import type { PartnerState, FundingEntity } from "components"
import type { networks } from "pages/DiscountConfig/utils"

export type PaydaysMutationResponse<T = unknown> = {
  ok: boolean
} & T

export interface PayDaysWeeklyPaySettings {
  fee?: number
  enabled?: boolean
  pay_days?: [string]
  shift_types?: Record<string, boolean>
  employer_fee?: number
  employee_defaults?: {
    default?: boolean
    max_transfer_percent?: number
  }
  push_notification?: string
}

export interface PaydaysCompanyProperties {
  Author: string
  Generated: "rootweb" | "airtable"
  slug: string
  date_live?: string
  in_hypercare?: boolean
  funding_self?: boolean
  partnership?: string
  divisions?: boolean
  beneficiary_account_number?: string
  beneficiary_ledger_id?: string
  beneficiary_sort_code?: string
  payment_country_code?: string
  payment_trading_name?: string
  payment_number?: string
  payment_name?: string
  transfers_disabled?: boolean
  employee_defaults?: {
    max_transfer_percent: {
      default: number
      max: number
    }
    max_transfers_month: number
    monthly_transfer_limit: number
  }
  manager_mode?: {
    manager_demo_company_id?: string | null
  }
  nhs_trust_code?: string
  railsbank?: {
    payment_omnibus_ledger_id: string
    payment_omnibus_sort_code: string
    payment_omnibus_account_number: string
    payment_enduser_id: string
    payment_country_code: string
    beneficiary_ledger_id: string
  }
  modulr?: {
    payment_omnibus_ledger_id?: string
    payment_enduser_id?: string
    details?: {
      [key: string]: unknown
    }
  }
  lhv_uk?: {
    payment_omnibus_ledger_id?: string
    payment_enduser_id?: string
    details?: {
      [key: string]: unknown
    }
  }
  lhv?: {
    payment_omnibus_ledger_id?: string
    payment_enduser_id?: string
    details?: {
      [key: string]: unknown
    }
  }
  hubuc?: {
    payment_omnibus_ledger_id?: {
      iban?: string | null
      entity_id?: string | null
      enduser_type?: string | null
      ledger_id?: string | null
    }
    payment_enduser_id?: string | null
    payment_country_code?: string | null
    beneficiary_ledger_id?: string | null
    details?: {
      [key: string]: unknown
    }
    kyb_status?: string | null
  }
  lloyds?: {
    details?: {
      [key: string]: unknown
    }
    payment_omnibus_ledger_id?: string | null
    payment_enduser_id?: string | null
    payment_country_code?: string | null
  }
  frontline?: {
    app_url?: string
  }
  salary_provider?: string
  safestream_provider?: string
  funding_entity?: FundingEntity
  weekly_pay_settings?: PayDaysWeeklyPaySettings
  aml_alert_enabled?: boolean
  credit_alert_enabled?: boolean
  fraud_alert_enabled?: boolean
  [key: string]: unknown
  funding_alert_email?: string
  credit_limit?: {
    limit: number
    version: number
    computed_at: string
    override: number
  }
}

export interface PaydaysCompany {
  company_id: string
  corporate_group_id?: string | null
  created_at?: string
  is_demo?: boolean
  name: string
  partner_state?: PartnerState
  company_monthly_transfer_limit?: number
  default_currency?: string
  default_pay_schedule_id?: string
  logo_url?: string
  timezone?: string
  properties: PaydaysCompanyProperties
}

export interface GenericJSONSchemaProperty {
  title: string
  types: Array<string>
  description: string
  internal_name: string
  category: Array<string>
}

export type PaydaysCompanies = PaydaysCompany[]

export interface PaydaysPaySchedule {
  pay_schedule_id: string
  updated_at: string
  created_at: string
  name: string
  periodicity: string
  accrual_type: string
  company_id: string
  timezone?: `${string}/${string}` | null
  paid_on_timezone: `${string}/${string}` | null
  properties: {
    is_unlocked?: boolean | null
    is_archived?: boolean | null
    [key: string]: unknown
  }
}

export interface PaydaysNextPaySchedule
  extends Pick<PaydaysPaySchedule, "company_id" | "name" | "pay_schedule_id"> {
  next_paid_on: string
}

export type PaydaysAddPayScheduleResponse = Pick<
  PaydaysPaySchedule,
  | "name"
  | "periodicity"
  | "accrual_type"
  | "company_id"
  | "timezone"
  | "paid_on_timezone"
  | "pay_schedule_id"
>

export interface PaydaysPayScheduleUsedByCompany {
  pay_schedule_id: string
  name: string
  periodicity: string
  accrual_type: string
  pay_period_start: string
  next_payday: string
  num_employees: number
}

export interface PaydaysCompanyPaySchedule {
  company_id: string
  name: string
  num: number
  paid_on: string
  past: boolean
}

export interface ACHDebitExclusionPhrase {
  id: string
  phrase: string
  created_at: string
}

export interface ACHDebitInclusionPhrase {
  id: string
  phrase: string
  created_at: string
}

export type PaydaysPartner =
  | "FOURTH.v1"
  | "FOURTH.v2"
  | "WORKDAY"
  | "ROTAREADY"
  | "PEOPLEHR"
  | "PUSHAPI"
  | "BROWSER_UPLOAD"
  | "SFTP"
  | "INNOVISE"
  | "INNOVISE.v2"
  | "ALLOCATE"
  | "CLOUDSTAFF"
  | "OSV_DIRECT"
  | "DAYFORCE"
  | "PATCHWORK"
  | "NHS_RPA"
  | "LEGION.v1"
  | "NHS_ESR_API"
  | "ORACLE"
  | "BIDVEST"
  | "S4LABOUR"
  | "ORACLE_FUSION"
  | "ADP_IHCM"
  | "CIPHR"
  | "PLANDAY"
  | "SDWORX"
  | "DEPUTY"
  | "PEOPLE_FIRST"
  | "ZELLIS_ZIP_API"
  | "ZELLIS_OPTIMUM"
  | "ROTAGEEK"
  | "WORKDAY.v2"
  | "COOLCARE"
  | "UKG_PRO"
  | "UKG_WFM"
  | "WORKDAY_RAAS_API"
  | "WORKDAY_SOAP_API"

export interface PaydaysCompanyIntegrationProperties {
  [key: string]: unknown
}
export interface PaydaysCompanyIntegration {
  company_integration_id: string
  created_at: string
  updated_at: string
  partner: PaydaysPartner
  standard: string
  sequence: number
  linked_at: string
  unlinked_at: string | null
  s3_bucket_url?: string | null
  file_headers?: {
    shifts_input?: string[] | null
    employees_input?: string[] | null
  }
  properties?: PaydaysCompanyIntegrationProperties
}

export interface PaydaysCompanyFourthEmployee {
  assignmentenddate: string | null
  emailhome: string
  employeeid: string
  employmentenddate: string | null
  employmentreference: string | null
  employmentstatus: string | null
  faid: string
  firstname: string
  lastname: string
  payrollid: string
  payrollnumber: string
}

export interface PaydaysNewPaySchedule {
  new_pay_schedule_id: string
}

export interface PaydaysPayScheduleDate {
  paid_on?: string | null
  pay_period_start?: string
  pay_period_end?: string | null
  pay_schedule_date_id: string
  blackout_period_start?: string | null
  suggested_next_paid_on?: string | null
  pending: boolean
  properties?: {
    lock_date?: string | null
    [key: string]: unknown
  } | null
}

export interface PaydaysUser {
  created_at: string
  email: string
  email_sign_in: boolean
  email_verified: boolean
  email_optout?: boolean
  is_locked: boolean
  password_expires_at: string | null
  phone: string
  phone_verified: boolean
  requires_2fa: boolean
  requires_reset: boolean
  updated_at: string
  user_id: string
  username: string
  properties?: {
    phone_verified_at?: string | null
    portal_suspended?: boolean | null
    [key: string]: unknown
  }
}

export interface PaydaysAdminUser
  extends Omit<PaydaysUser, "properties">,
    Omit<PaydaysCompany, "created_at"> {}

export interface PaydaysUserHistory {
  user_history_log_id?: string | null
  logged_at?: string | null
  user_id?: string | null
  performed_by_user_id?: string | null
  performed_by_username?: string | null
  performed_by_is_root?: boolean | null
  activity?: string | null
  old_data?: string | null
  new_data?: string | null
  properties?: {
    company_id?: string | null
    [key: string]: unknown
  }
}

export interface PaydaysUserLogin {
  user_login_id?: string | null
  attempted_at?: string | null
  was_successful?: boolean | null
  properties?: {
    [key: string]: unknown
  }
}

export interface PaydaysUserRole {
  assigned_at: string
  entity_id: string
  role_id: string
  user_id: string
}

export interface PaydaysRootUser {
  username: string
  root_user_id: string
  properties?: Record<string, unknown> | null
  created_at?: string | null
  updated_at?: string | null
}

export interface PaydaysCompanyConfiguration {
  companies: PaydaysCompany[]
  pay_schedules: PaydaysPaySchedule[]
  pay_schedule_dates: PaydaysPayScheduleDate[]
  divisions: unknown[]
  users: PaydaysUser[]
  user_roles: PaydaysUserRole[]
  uuids_to_replace: string[]
}

export interface PaydaysPropertyChangelog {
  changes: {
    values_changed?: Record<string, unknown>
    dictionary_item_added?: Record<string, unknown>
    dictionary_item_removed?: Record<string, unknown>
    transfers_disabled?: boolean
  }
  created_at: string
  entity: string
  entity_id: string
  notes: string
  sequence: number
  user_id: string
  username: string
}

export interface PaydaysCompanyAdmin {
  created_at: string
  email: string
  email_verified: boolean
  is_locked: boolean
  last_login_at: string | null
  phone: string
  phone_verified: boolean
  roles: string[]
  updated_at: string
  user_id: string
  username: string
  requires_2fa: boolean
  properties?: {
    portal_suspended?: boolean | null
    [key: string]: unknown
  }
}

export interface RaisePortalUser {
  created_at: string
  email: string
  email_verified: boolean
  is_locked: boolean
  last_login_at: string | null
  phone: string
  phone_verified: boolean
  role: string
  entity: string
  updated_at: string
  user_id: string
  username: string
  requires_2fa: boolean
  properties?: {
    portal_suspended?: boolean | null
    [key: string]: unknown
    talent_pool?: {
      first_name?: string
      last_name?: string
    }
  }
}

export enum PaydaysCompanyContactType {
  ENROLLMENT_ISSUES = "ENROLLMENT_ISSUES",
  SALARY_ISSUES = "SALARY_ISSUES",
  VULNERABLE_EMPLOYEE = "VULNERABLE_EMPLOYEE",
  SHIFT_ISSUES = "SHIFT_ISSUES",
  SELF_SERVICE_PORTAL = "SELF_SERVICE_PORTAL",
  HR_ISSUES = "HR_ISSUES",
  TECHINCAL_TEAM = "TECHINCAL_TEAM",
  PAY_SCHEDULE = "PAY_SCHEDULE",
  ACCOUNTS_PAYABLE = "ACCOUNTS_PAYABLE",
}

export interface PaydaysCompanyContact {
  company_contact_id: string
  company_id: string
  full_name: string
  email: string
  phone?: string | null
  contact_types:
    | {
        company_contact_type: PaydaysCompanyContactType
        created_at?: string | null
        created_by_user_id?: string | null
        username?: string | null
      }[]
    | null
  created_at?: string | null
  updated_at?: string | null
  updated_by_user_id?: string | null
  updated_by_username?: string | null
  properties?: unknown | null
}

export interface PaydaysCompanyAdminResponse {
  user_id: string
  message: string
}

export interface PaydaysCompanyEmployeeSnapshot {
  current_state: string
  count: number
  percentage_of_total: number
  percentage_of_eligible: number
}

interface PaydaysComment {
  comment: string
  comment_id: string
  created_at: string
  entity: string
  entity_id: string
  updated_at: string
  user_id: string
}

export interface PaydaysCompanyComment extends PaydaysComment {
  entity: "companies"
}

export interface PaydaysAddCompanyComment {
  comment_id: string
  user_id: string
}

export interface PaydaysCompanySearchAddress {
  address_line_1: string
  country: string
  locality: string
  postal_code: string
  premises: string
}

export interface PaydaysCompanySearchItemLinks {
  self: string
}

export interface PaydaysCompanySearchItemMatches {
  snippet: string[]
  title: number[]
}

export interface PaydaysCompanySearchItem {
  address: PaydaysCompanySearchAddress
  address_snippet: string
  company_number: string
  company_status: string
  company_type: string
  date_of_creation: string
  description: string
  description_identifier: string[]
  kind: string
  links: PaydaysCompanySearchItemLinks
  matches: PaydaysCompanySearchItemMatches
  snippet: string
  title: string
}

export interface PaydaysCompanySearch {
  items: PaydaysCompanySearchItem[]
  items_per_page: number
  kind: string
  page_number: number
  start_index: number
  total_results: number
}

export interface PaydaysAddPayrollReturnBankDetails {
  beneficiary: { beneficiary_ledger_id: string }
  success: boolean
}

export type PaydaysSubscriptions =
  | {
      config: {
        amount: number
        company_id: string
        created_at: string
        updated_at: string
        currency: string
        end_date: string | null
        start_date: string
        subscription_id: string
      }
    }
  | false

export type PaydaysAddSubscriptions = {
  subscription_id: string
}

export type PaydaysCompanyFundingEntity = {
  funding_entity: string
  status: string
  root_action_id: string
}

type DiscountType = "voucher" | "promotion" | "cashback_only" | "product_feed"

export interface AdvertiserDiscountProperties {
  priority?: number
  hide_discount?: boolean
  is_custom?: boolean
  is_boosted?: boolean
  boosted_text?: string | null
  promo_image_url?: string
  display_name?: string | null
  description_override?: string | null
  terms_override?: string | null
  voucher_code_override?: string | null
  requires_single_use_code?: boolean
  single_use_code_refresh_period?: number | null
  full_price?: number | null
  sale_price?: number | null
}

type Networks = typeof networks[number]

export interface PaydaysDiscountCreatePayload {
  discount_retailer_id: string
  discount_type: DiscountType
  network_internal_id?: string
  network: Networks
  title: string
  description: string
  url: string
  start_date: string
  end_date: string
  voucher_code: string | null
  properties: {
    requires_single_use_code?: boolean
    single_use_code_refresh_period?: number | null
    full_price?: number | null
    sale_price?: number | null
    promo_image_url?: string | null
  }
  terms?: string
}

export interface PaydaysRetailerCreatePayload {
  name: string
  slug: string
  properties: {
    show_retailer: false
  }
  network_properties: PaydaysDiscountNetworkProperties
}

export interface PaydaysDiscount {
  advertiser_id: string
  canonical_title: string
  created_at: string
  deleted_at?: string
  description?: string
  discount_id: string
  discount_priority: number
  discount_retailer_id: string
  discount_type: DiscountType
  end_date?: string
  properties: AdvertiserDiscountProperties
  retailer: string
  start_date?: string
  terms?: string
  title: string
  url: string
  voucher_code?: string
  single_use_code_count?: number
}

export interface PaydaysDiscountsConfig {
  categories: string[]
}

export interface CommissionRule {
  code: string
  name: string
  type: "fixed" | "percentage"
  amount: number
  currency: string
}

export interface PaydaysDiscountNetworkPropertiesInner {
  id?: string | number | undefined
  show_discounts: boolean
  enable_cashback: boolean
  enable_instant_cashback: boolean
  collect_commission_info: boolean
  tracking_url?: string
  commission_details?: {
    range: {
      percentage?: {
        min: number
        max: number
        currency: string
      }
      fixed?: {
        min: number
        max: number
        currency: string
      }
    }
    rules?: CommissionRule[]
  }
}

export type PaydaysDiscountNetworkProperties = Partial<
  Record<Networks, PaydaysDiscountNetworkPropertiesInner | undefined>
>

export interface CommissionRange {
  max: number
  min: number
  type: "percentage" | "fixed"
  currency: "GBP"
}

export interface CommissionOverrides {
  range: CommissionRange | null
  show_rules?: boolean
  rules?: Record<Networks, Record<string, string>>
}

export interface PaydaysDiscountRetailerProperties {
  logo_url?: string
  promo_image_url?: string
  hero_tile_image_url?: string
  terms_url?: string
  show_retailer?: boolean
  supports_app_tracking?: boolean
  is_recommendable?: boolean
  cashback_ratio?: number
  categories?: string[]
  commission_overrides?: CommissionOverrides
}

export interface PaydaysDiscountRetailers {
  discount_retailer_id: string
  display_name: string
  slug: string
  name: string
  canonical_name: string
  cashback_ratio: number
  network_properties: PaydaysDiscountNetworkProperties
  properties: PaydaysDiscountRetailerProperties
}

export interface PaydaysDiscountCategory {
  discount_category_id: string
  display_name: string
  rank: number
  properties: {
    top_retailer_ids: string[]
    hide_category: boolean | null
  }
  slug: string
}

export type PaydaysDiscountCategoryHome = Omit<
  PaydaysDiscountCategory,
  "properties" | "slug"
>
export type PaydaysDiscountCategoryCreatePayload = Omit<
  PaydaysDiscountCategory,
  "discount_category_id" | "properties" | "rank"
>
export type PaydaysDiscountCategoryUpdatePayload = Omit<
  PaydaysDiscountCategory,
  "discount_category_id" | "rank" | "slug"
>

export interface PaydaysDiscountCategoryRankingsPayload {
  discount_category_id: string
  direction: "increase" | "decrease"
}

export interface PaydaysDiscountsPromotedGet {
  discount_id: string
  carousel_image: string
  deleted_at: string | null
  end_date: string | null
  is_valid_discount: boolean
  retailer: string
  retailer_logo_url: string
  title: string
  promotion_start_at?: string | null
  promotion_end_at?: string | null
  promoted_type?: string
  discount_promotion_id: string
  discount_type: DiscountType
}

export interface PaydaysDiscountsPromotedPatch {
  discount_id: string
  carousel_image: string
}

export interface PaydaysDiscountForRetailer {
  canonical_title: string
  discount_id: string
  discount_priority: number
  discount_type: DiscountType
  deleted_at: string | null
  end_date: string | null
  is_discount_visible: boolean
  is_custom: boolean
  retailer: string
  start_date: string | null
  title: string
  deep_link: string
}

export interface PaydaysDiscountsHomepageProductFeed {
  offer_id: string
  offer_type: "discount_product_feed"
  full_price: number
  sale_price: number
  product_cashback_amount: number
  title: string
  subtitle: string
  product_image_url: string
  retailer: string
}

export interface PaydaysEmailTemplate {
  company_id?: string | null
  company_name?: string | null
  created_at: string
  email_template_id: string
  html: string
  is_campaign: boolean
  placeholders?: unknown | null
  plaintext: string
  properties: null
  template_id: string
  updated_at: string
}

export interface PaydaysEmailTemplateContent {
  email_template_id: string
  html: string
  partnership: string
  placeholders?: unknown | null
  plaintext: string
  properties?: unknown | null
  template_id: string
  version?: string
}

export interface PaydaysEmailTemplateRender {
  html: string
  plaintext: string
}

export interface PaydaysCompanySalaryActivity {
  company_id: string
  company_name: string
  credits_applied: number
  currency: string
  from: string
  language_code: string
  partnership: string
  payment_fees: number
  payment_salaries: number
  payment_savings: number
  payment_transfers: number
  salaries: number
  to: string
  transfers: number
}

export type PaydaysUpdateDefaultTransferLimits = {
  success: boolean
}

export interface PaydaysEmployee {
  assigned_at: string
  banking_info: null
  company_id: string
  company_partnership: string | null
  created_at: string
  current_state: PaydaysEmployeeState
  default_wagerate: number
  division_assigned_at: string | null
  division_id: string | null
  division_name: string | null
  employee_code: string
  employee_id: string
  enrollment_failed_reason?: { reason?: string }
  entity_id: string
  full_name: string
  handle_employee_assignments: null
  integration_properties: {
    email?: string
    employee_id?: string
    full_name?: string
    personal_account_number?: string
    personal_sort_code?: string
    salary?: string
    started_on?: string
    [key: string]: string | undefined
  } | null
  invited_at: null
  last_login_at: string
  max_transfer_percent: number
  max_transfers_month: number
  min_transfer_balance: number
  monthly_transfer_limit: number
  pay_schedule_id: string
  payment_country_code: string
  photo_url: string | null
  properties: {
    beneficiary_account_number?: string
    beneficiary_ledger_id?: string
    beneficiary_sort_code?: string
    company_enduser_id?: string
    company_omnibus_ledger_id?: string
    confirmed_bank_details?: boolean
    daily_sick_pay?: null | number
    disable_salary_processing?: boolean
    exclude_from_nhs_rpa?: boolean
    furloughed?: string
    is_proto_employee?: boolean
    big_salary?: boolean
    login_employee_code?: string
    note?: string | null
    notes: string[]
    optout_completed_at?: string
    optout_initiated_at?: string
    overtime_shift_percent_available: boolean | null
    paused: boolean | null
    payment_account_number?: string
    payment_ledger_id?: string
    payment_sort_code?: string
    squib?: string
    transfers_disabled?: boolean
    in_blackout?: boolean
    railsbank?: {
      beneficiary_ledger_id: string
      company_enduser_id: string
      company_omnibus_ledger_id: string
      payment_account_number: string
      payment_ledger_id: string
      payment_sort_code: string
    }
    hubuc?: {
      company_enduser_id: string
      company_omnibus_ledger_id: string
      payment_ledger_id: string
    }
    modulr?: Record<string, unknown>
    locked_until?: string | null
    [key: string]: unknown
    vulnerable?: boolean
    app_features?: Record<string, string | boolean>
  }
  role_id: string
  started_on: string
  terminated_at: string | null
  transfer_count: number | null
  updated_at: string
  user_email: string
  user_id: string
  work_email: string
  work_phone: string
  salary_properties?: {
    default_wagerate: number
    salary: number
    changed_at: string
    salary_accrual_schedule: "MONTHLY" | "EVERYDAY"
    salary_period: "YEAR" | "MONTH" | "WEEK" | "DAY"
    salary_or_hourly: "hourly" | "salary"
  }
  in_blackout?: boolean
}

export interface PaydaysEmployeePatch {
  company_id?: string
  employee_code?: string
  employee_id?: string
  full_name?: string
  max_transfer_percent?: string
  pay_schedule_id?: string
  started_on?: string
  work_email?: string
  properties?: {
    note?: string | null
  }
}

type PaydaysEmployeeTransferLimits = {
  absolute_maximum_transfer?: number | null
  absolute_maximum_transfer_month?: number | null
  auto_stream_shift_types?: string[] | null
  auto_stream_shifts?: boolean | null
  employee_max_transfers_month?: number | null
  employee_maximum_transfer?: number | null
  employee_maximum_transfer_month?: number | null
  max_transfer_percent?: number | null
  max_transfers_month?: number | null
  maximum_transfer?: number | null
  maximum_transfer_per_month?: number | null
  show_auto_stream_setting?: boolean | null
}

export interface PayDaysSettings {
  alert_message: string | null
  alert_message_settings: string | null
}

export interface PayDaySettingsPatch {
  name: string
  value: string | null
}

export interface OpenPayPeriodBreakdown {
  net_balance: number
  outstanding: {
    total: number
    total_principal: number
  }
  pay_period: {
    end: string
    paid_on: string
    start: string
  }
  wages: {
    available: number
    gross: number
    hours: number
  }
}

export interface PaydaysEmployeeBalance {
  _temporary: {
    partner_state: string
    transferred: number
  }
  accrual_type: number
  alert_message: string | null
  available_from_current_pay_period: number
  available_from_previous_pay_period: number
  available_to_transfer: number
  balance_reasons: PaydaysBalanceReason[]
  balance_updated: boolean
  pay_schedule_paycheck_date: string
  pay_schedule_start_date: string
  last_pay_period_paid_on: string
  last_pay_period_start: string
  days_until_payday: number
  employee_contract_id: string | null
  employee_contract_occupancy_id: string | null
  employee_contract_ids: (string | null)[]
  employee_contract_type: PaydaysEmployeeContractType
  pay_schedule_name: string
  employee_transfer_limits: PaydaysEmployeeTransferLimits
  employer_fee: number
  employer_fee_instant: number
  furlough_minimum_available: number
  gross_available: number
  gross_earned: number
  hours_worked: number
  intercom_info: {
    available_to_transfer: number
    current_limit_remaining: number
    current_num_transfers: number
    current_transferred: number
    employee_bank: string
    employee_code: string
    funding_self: boolean
    gross_available: number
    hourly_or_salary: string
    max_transfer_percent: number
    max_transfers_month: number
    min_transfer_balance: number
    monthly_transfer_limit: number
    next_paycheck_date: string
    num_transfers_this_period: number
    pay_accrual_end: string
    pay_accrual_start: string
    pay_schedule_type: string
    salary: number
    sme: boolean
    state: string
    total_num_transfers: number
    total_debt: number
    total_outstanding: number
    total_transferred: number
    transfers_disabled: boolean
  }
  last_pay_period_gross_earned: number
  last_paycheck_date: string
  limit_remaining: number
  max_transfer_percent: number
  max_transfers_month: number
  min_transfer_balance: number
  monthly_transfer_limit: number
  next_paycheck_date: string
  num_transfers: number
  num_transfers_this_period: number
  open_pay_period_breakdown: Record<string, OpenPayPeriodBreakdown[]> | null
  pay_accrual_end: string
  pay_accrual_start: string
  pay_schedule_id: string
  pay_schedule_ids: string[]
  pay_schedule_lock_date: string | null
  shifts_worked: number
  total_balance: number
  total_debt: number
  total_outstanding: number
  total_outstanding_principal: number
  transfer_button_text: string
  transfer_fee: number
  transfer_fee_instant: number
  transfer_fee_message: string
  transferred: number
  transfers_disabled: boolean
  transfers_this_period: number
  within_arrears_period: boolean
}

export type PaydaysEmployeeBalanceHistory = {
  available_to_transfer?: number | null
  created_at?: string | null
  employee_id?: string | null
  properties?: PaydaysEmployeeBalance
}

export type PaydaysEmployeeActivityHistory = {
  activity: string
  logged_at: string
  properties: {
    new?:
      | {
          beneficiary_account_number?: string
          beneficiary_ledger_id?: string
          beneficiary_sort_code?: string
          company_enduser_id?: string
          company_omnibus_ledger_id?: string
          confirmed_bank_details?: boolean
          daily_sick_pay?: null
          disable_salary_processing?: boolean
          notes?: []
          note?: string
          overtime_shift_percent_available?: null
          paused?: boolean
          paused_reason?: string
          payment_account_number?: string
          payment_ledger_id?: string
          payment_sort_code?: string
          railsbank?: {
            beneficiary_ledger_id: string
            company_enduser_id: string
            company_omnibus_ledger_id: string
            payment_account_number: string
            payment_ledger_id: string
            payment_sort_code: string
          }
          updated_payroll?: boolean
        }
      | string
      | null
    old?:
      | {
          beneficiary_account_number?: string
          beneficiary_ledger_id?: string
          beneficiary_sort_code?: string
          company_enduser_id?: string
          company_omnibus_ledger_id?: string
          confirmed_bank_details?: boolean
          daily_sick_pay?: null
          disable_salary_processing?: boolean
          notes?: []
          note?: string
          overtime_shift_percent_available?: null
          paused?: boolean
          payment_account_number?: string
          payment_ledger_id?: string
          payment_sort_code?: string
          railsbank?: {
            beneficiary_ledger_id: string
            company_enduser_id: string
            company_omnibus_ledger_id: string
            payment_account_number: string
            payment_ledger_id: string
            payment_sort_code: string
          }
          updated_payroll?: boolean
        }
      | string
      | null
  }
  user_id: string | null
  username: string
}

export enum PaydaysEmployeeState {
  NEW = "NEW",
  ENROLLED = "ENROLLED",
  DISABLED = "DISABLED",
  ENROLLING = "ENROLLING",
  INSTALLED = "INSTALLED",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
}

export enum PaydaysEmployeeContractType {
  SALARY = "SALARY",
  HOURLY = "HOURLY",
  STANDARD_SHIFTS = "STANDARD_SHIFTS",
  OVERTIME_SHIFTS = "OVERTIME_SHIFTS",
  EXPENSES = "EXPENSES",
}

export interface PaydaysEmployeeMicrodeposit {
  amount: number
  attempts: number
  created_at: string
  verified_at: string
  transaction_id: string
  employee_id: string
}

export interface PaydaysEmployeeMicrodeposits {
  microdeposits: PaydaysEmployeeMicrodeposit[]
}

export type PaydaysEmployeeHistory = {
  activity: PaydaysEmployeeActivityHistory[]
  enrollment: {
    company_id: string
    created_at: string
    employee_id: string
    sequence: number
    state: PaydaysEmployeeState
    properties?: {
      reason?: string
    }
  }[]
}

export type Schema = {
  type: string
  title?: string
  description?: string
  additionalProperties?: boolean
  hidden?: boolean
  properties?: {
    [key: string]: Schema
  }
}

export interface PaydaysCompanyDivision {
  division_id: string
  division_name: string
  employee_count: number
  signup_percent: number
  wagestream_users: null
}

export interface PaydaysDivision {
  company_id: string
  created_at: string
  division_id: string
  name: string
  properties: unknown
  updated_at: string
}

export interface PaydaysAddCompanyDivision {
  company_id: string
  division_id: string
  name: string
  properties: unknown
}

export interface PaydaysCompanyDivisionManager {
  created_at: string
  division_id: string
  division_name: string
  email: string
  email_sign_in: boolean
  email_verified: boolean
  is_locked: boolean
  last_login_at: string | null
  password_expires_at: string | null
  phone: string | null
  phone_verified: boolean
  requires_2fa: boolean
  requires_reset: boolean
  updated_at: string
  user_id: string
  username: string
}

export interface PaydaysCompanyDataSet {
  dataset_id: string
  created_at: string
  dataset_name: string
  properties: Record<string, string | number>
}

export interface PaydaysCompanyFile {
  client_upload_id: string
  file_name: string
  uploaded_by: string
  uploaded_at: string
  company_id: string
  user_id: string
}

export interface PaydaysWebUser {
  access_type?: string | null
  email?: string | null
  web_user_id?: string | null
}

export interface PaydaysHypercareCheck {
  hypercare_check_id: string
  created_at: string
  company_id: string
  name: string
  properties: {
    description: string
    pass_condition: string
  }
  result: "success" | "warning" | "failure" | "errored" | "undetermined"
  message: string
}

export interface PaydaysInnoviseIntegrationBranchEntry {
  branch_id: number
  branch_code: string
  branch_name: string
}

export type PaydaysEntity =
  | "EMPLOYEES"
  | "SHIFTS"
  | "EMPLOYEES_EXTENDED"
  | "ABSENCES"
  | "EXPANDED_EMPLOYEES"
  | "ROTA"
  | "JOBS"

export interface PaydaysIntegrationSyncResults {
  company_id: string
  sync_result_id: string
  company_integration_id: string
  item_id: string | null
  uploader_username: null | string
  original_key: string | null
  started_at: string
  completed_at: string
  duration: number
  entity: PaydaysEntity
  matches: number
  deletes: number
  inserts: number
  updates: number
  summary?: {
    validation_report_sent?: boolean
    deletes?: {
      failure: number
      success: number
    }
    inserts?: {
      failure: number
      success: number
    }
    updates?: {
      failure: number
      success: number
    }
    msg: string
    num_rows: number
    is_ok: boolean
  }
}

export type PaydaysSyncOperation = "update" | "delete" | "insert"

export interface PaydaysIntegrationSyncResult {
  company_id: string
  entity_id: string
  err: string
  friendly_err: string
  index: number
  is_ok: boolean
  item: {
    employee_code: string
    employee_id: string
    full_name: string
    integration_properties: unknown
    properties: unknown
    salary: number | null
    started_on: string | null
    work_email: string
    work_phone: string
  }
  operation: PaydaysSyncOperation
  sync_result_id: string
  sync_result_item_id: string
}

export interface PaydaysEmployeeSyncResult {
  started_at: string
  partner: PaydaysPartner
  entity: PaydaysEntity
  operation: PaydaysSyncOperation
  is_ok: boolean
  item: {
    properties: unknown
    [key: string]: unknown
  }
  err: string
  upload_original_key?: string | null
  upload_item_id?: string | null
}

export interface PaydaysFourthTable {
  batch_inserted_at: string
  cnt_processed: number
  cnt_tables: number
  completed_at: string | null
  duration: string | null
  employees_deleted: number
  employees_inserted: number
  employees_updated: number
  previous_completed_at: null | string
  shifts_deleted: number
  shifts_inserted: number
  shifts_updated: number
  started_at: string
  sync_no: number
  table_prefix: string
}

export interface PaydaysCompanyCreditLimit {
  editable: boolean
  enabled: boolean
  limit: number
  usage: number
  taper: number
  ee_count: number
  credit_per_ee: number
}

export interface PaydaysSmeSubscription {
  checkout_properties: {
    client_secret: string
  }
  created_at: string
  properties: unknown
  purpose: string
  status: string
  stripe_customer_id: string
  stripe_session_id: string
  stripe_status: string | null
  stripe_subscription_id: string
  subscription_id: string
  updated_at: string
}

export interface PaydaysSmeRelevantPeople {
  company_id: string
  created_at: string
  first_name: string
  kyc_status: "SUCCESS" | "REVIEW" | "FAILURE"
  last_name: string
  middle_name: string
  properties: unknown
  relevant_person_id: string
  role: string
  updated_at: string
  user_id: string
}

export interface PaydaysSmeOfficer {
  address: {
    address_line_1: string
    country: string
    locality: string
    postal_code: string
    premises: string
    region: string
  }
  resigned_on?: string
  appointed_on: string
  country_of_residence: string
  date_of_birth: {
    month: number
    year: number
  }
  links: {
    officer: {
      appointments: string
    }
    self: string
  }
  name: string
  nationality: string
  occupation: string
  officer_role: string
}

export interface PaydaysSmePersonWithSignificantControl {
  address: {
    address_line_1: string
    country: string
    locality: string
    postal_code: string
    premises: string
    region: string
  }
  country_of_residence: string
  date_of_birth: {
    month: number
    year: number
  }
  kind: string
  links: {
    self: string
  }
  name: string
  name_elements: {
    forename: string
    surname: string
    title: string
  }
  nationality: string
  natures_of_control: string[]
  notified_on: string
}

export interface PaydaysSmeComplianceStatus {
  company_id: string
  created_at: string
  dbs_check: boolean
  officers: {
    active_count: number
    inactive_count: number
    items: PaydaysSmeOfficer[]
    items_per_page: number
    kind: string
    links: {
      self: string
    }
    resigned_count: number
    start_index: number
    total_results: number
  }
  profile: unknown
  registered_name: string
  registered_number: string
  right_to_work: boolean
  significant_control: {
    active_count: number
    ceased_count: number
    items: PaydaysSmePersonWithSignificantControl[]
    items_per_page: number
    links: {
      self: string
    }
    start_index: number
    total_results: number
  }
  status: string
}

export interface PaydaysIntegrationSFTPReportInfo {
  file_name: string
  key: string
  last_modified?: string
}

export interface PaydaysIntegrationsLatestBucketFile {
  original_key: string
  moved_key: string
  uploaded_at: string
  processed_at: string
  partner: string
}

export interface PaydaysOnboardingTask {
  code: string
  name: string
  status: "COMPLETE" | "NEW"
  workflow_task_id: string
}

export interface PaydaysOnboardingWorkflow {
  added_employees: boolean
  completed: boolean
  screening_complete: boolean
  tasks: PaydaysOnboardingTask[]
}

export type PaydaysESRLookup = Record<string, string> & {
  EmployeeFound: boolean
  VerificationStatus: string
}

export interface PaydaysRPARequest {
  company_ids: string[]
  operation: "GET_BANKING" | "WRITE_BANKING"
}

export interface PaydaysGetRPARequestData {
  nhs_rpa_request_id: string
  started_at: string
  completed_at: string
  failures: number
  successes: number
  total: number
  operation: "GET_BANKING" | "WRITE_BANKING"
  properties: {
    is_test: boolean
    result: "SUCCESS" | "HUNG" | "ERROR"
  }
}

export interface PaydaysRPAData {
  errors: Record<string, string>[]
  ok: boolean
}

export interface PaydaysTestRPAData {
  error: string
  ok: boolean
}

export interface PaydaysAllocateIntegrationShiftItem {
  employee_id?: string
  ended_at?: string
  hours?: number
  properties?: {
    payroll_state?: string
    shift_code?: string
  }
  started_at?: string
  wages?: string
  worked_on?: string
}

export interface PaydaysAllocateIntegrationShiftData {
  dob?: string
  shifts?: PaydaysAllocateIntegrationShiftItem[]
  surname?: string
}

export interface PaydaysSortcode {
  btrim: string
  logo_url: null | string
  short_branch: string
  short_name: string
  sort_code: string
}

export interface PaydaysBankingBeneficiary {
  asset_class: string
  asset_type: string
  beneficiary_holder: {
    company: {
      name: string
    }
    enduser_id: string
  }
  beneficiary_id: string
  beneficiary_meta: {
    created_at: string
    employee_id: string
    purpose: string
  }
  beneficiary_status: string
  created_at: string
  default_account: {
    account_id: string
    account_number: string
    asset_class: string
    asset_type: string
    bank_code: string
    bank_code_type: string
    created_at: string
    last_modified_at: string
  }
  holder_id: string
  last_modified_at: string
  person: {
    address: {
      address_iso_country: string
    }
    name: string
  }
  screening_monitored_search: boolean
  uk_account_number?: string
  uk_sort_code?: string
  bic_swift?: string
  iban?: string
}

export interface PaydaysSalaryTransfer {
  timestamp?: string | null
  amount?: number | null
  fee?: number | null
  transaction_type?: string
}

export interface PaydaysSalaryDisabled {
  queued_at: string
  name: string
  employee_id: string
  company_id: string
  company_name: string
  currency: string
  amount: number
  salary_id: string
  disabled_reason: string
  completed_at: string
}

export interface PaydaysSalary {
  company_id?: string | null
  company_name?: string | null
  credits_applied?: number | null
  currency?: string | null
  employee_id?: string | null
  employee_code?: string | null
  fee_collected?: number | null
  name?: string | null
  outstanding_fees?: number | null
  outstanding_withdrawals?: number | null
  overdue_fees?: number | null
  overdue_withdrawals?: number | null
  payment_out?: number | null
  salary_id?: string | null
  salary_in?: number | null
  timestamp?: string | null
  withdrawals_repaid?: number | null
  last_salary_ts?: string | null
  salary_ts?: string | null
  salary?: number | null
  repayment_withdrawls?: number | null
  repayment_fees?: number | null
  account?: string | null
  pay?: number | null
  safestream_deduction?: number | null
  new_carried_balance_transfers?: number | null
  new_carried_balance_fees?: number | null
  transfers?: PaydaysSalaryTransfer[] | null
  old_carried_balance_transfers?: number | null
  old_carried_balance_fees?: number | null
  new_balance_transfers?: number | null
  new_balance_fees?: number | null
  carryover?: boolean | null
  arrears?: boolean | null
  salary_actions?: number | null
  total_salary_action_deductions?: number | null
  voucher_deduction?: number | null
  overages?: {
    amount: number
    repaid_on: string
  }[]
}

export interface SalariesExpected {
  company_id: string
  company_name: string
  pay_schedule: string
  pay_schedule_id: string
  next_paid_on: string
  expected_salaries: number
}

export interface SalariesNoPay {
  full_name: string
  employee_id: string
  company_id: string
  company_name: string
  salary_in: number
  payment_out: number
  salary_id: string
}

export interface SalariesActual {
  company_id: string
  company_name: string
  employees: number
  salaries_in: number
  salaries_out: number
  total_salaries: number
  total_settled: number
  total_payments: number
  total_overdue: number
}

export interface SalariesFailed {
  full_name: string
  employee_id: string
  company_id: string
  company_name: string
  amount: number
  salary_id: string
  rejection_reasons: string
}

export interface PaydaysSalaryPayday {
  pay_expected: SalariesExpected[]
  pay_actual: SalariesActual[]
  pay_none: SalariesNoPay[]
  pay_fail: SalariesFailed[]
}

export interface PaydaysCorporateGroup {
  companies: {
    company_id?: string | null
    name?: string | null
  }[]
  corporate_group_id: string
  corporate_group_name: string
  created_at?: string | null
  updated_at?: string | null
  properties: unknown
}

export interface PaydaysEmployeeEmail {
  assigned_at: string
  destination_email: string
  email_log_id: string
  entity_id: string
  logged_at: string
  properties?: {
    submitted_at?: string
    sendgrid_message_id?: string
    [key: string]: unknown
  }
  role_id: string
  subject: string
  template_id: string
  user_id: string
}

export interface PaydaysUserEmail {
  assigned_at: string
  destination_email: string
  email_log_id: string
  entity_id: string
  logged_at: string
  properties?: {
    submitted_at?: string
    [key: string]: unknown
  }
  role_id: string
  subject: string
  template_id: string
  user_id: string
}

export interface PaydaysUserExperiment {
  active: boolean
  assigned_at: string
  experiment_code: string
  experiment_name: string
  treatment_code: string
  treatment_name: string
  properties?: {
    [key: string]: unknown
  }
}

export interface PaydaysLeaver {
  leaver_id: string
  created_at: string
  updated_at: string
  employee_id: string
  terminated_at: string
  company_name: string
  name: string
  company_id: string
  full_name: string
  work_email: string
}

export interface PaydaysShift {
  company_id: string
  created_at: string
  currency: string
  employee_id: string
  ended_at: string
  hours: number
  properties?: {
    info?: string
    job_code?: string
    integration_source?: string
    employee_contract_id?: string
    type?: string
    filtered?: boolean
  }
  rate: number
  shift_id: string
  source_id: string
  started_at: string
  updated_at: string
  removed_at?: string
  wages: number
  worked_on: string
  pay_schedule_id?: string | null
  pay_schedule_date_id?: string | null
  paid_on?: string | null
  pay_period_start?: string | null
  pay_period_end?: string | null
}

export interface PaydaysRota {
  act_hours: number | null
  act_rate: number | null
  act_wage: number | null
  approved: boolean
  employee_id: string | null
  end_at: string | null
  ended_at: string | null
  exp_wage: number
  properties?: {
    info?: string
    shift_code?: string
    type?: string
  }
  sched_hours: number
  sched_rate: number
  scheduled_for: string
  start_at: string | null
  started_at: string | null
  worked_on: string | null
}

export interface PaydaysTransaction {
  transaction_id?: string | null
  amount?: number | null
  banking_provider?: string | null
  company_id?: string | null
  company_name?: string | null
  employee_id?: string | null
  external_transaction_id?: string | null
  internal_transaction_id?: string | null
  name?: string | null
  operation?: string | null
  parent_id?: string | null
  properties?: {
    returned_to_employer?: string | null
    bpb_id?: string | null
  } & Record<string, unknown>
  status?: "FAILED" | string | null
  timestamp?: string | null
}

export interface PaydaysUnsettledTransaction {
  amount?: number | null
  banking_provider?: string | null
  company_id?: string | null
  company_name?: string | null
  days_into_pay_period?: number | null
  employee_id: string | null
  full_name?: string | null
  internal_transaction_id?: string | null
  operation?: string | null
  priority?: number | null
  properties?: Record<string, unknown>
  timestamp?: string | null
}

export interface PaydaysHistoryTransaction {
  event_day: string
  event_time: string
  type: string
  amount: number
  hours: number
  balance: number
  category: string
  source_id: string
}

export interface PaydaysAbsences {
  absence_id: string
  company_id: string
  created_at: string
  employee_id: string
  ended_at: string
  is_paid: boolean
  properties?: {
    absence_code?: string
    is_handled_by_deduction?: boolean
    reason?: string
    type?: string
    info?: string
  }
  source_id: string
  started_at: string
  updated_at: string
  removed_at?: string
}

export interface PaydaysTransferResponse {
  data?: PaydaysTransfer | null
  payment_data?: PaydaysPaymentData | null
}
export interface PaydaysPaymentData {
  pending_at?: string | null
  sent_at?: string | null
  completed_at?: string | null
}

export interface PaydaysBalanceReason {
  reason?: string | null
  data?: string | null
}

export interface PaydaysTransfer {
  company_id: string
  company_name: string
  created_at: string
  employee_code?: string | null
  employee_id: string
  full_name: string
  updated_at?: string | null
  transfer_id: string
  initiated_at: string | null
  completed_at: string | null
  net_amount: number
  gross_amount: number
  currency: string
  assigned_to_current_period: number | null
  company_modulr_properties?: {
    company_enduser_id?: string | null
    payment_ledger_id?: string | null
    company_omnibus_ledger_id?: string | null
  }
  properties?: {
    future_amount?: string | number | null
    future_amount_after?: string | null
    demo?: string | null
    test?: string | null
    payment_account_number?: string | null
    payment_sort_code?: string | null
    beneficiary_account_number?: string | null
    beneficiary_sort_code?: string | null
    company_enduser_id?: string | null
    payment_ledger_id?: string | null
    company_omnibus_ledger_id?: string | null
    beneficiary_ledger_id?: string | null
    modulr?: {
      beneficiary_ledger_id?: string | null
    }
    [key: string]: unknown
    voucher?: {
      retailer?: string | null
      voucher_id?: string | null
      value_minor?: number | null
    }
  }
  fee: number
  balance_before?: {
    accrual_type?: number | null
    alert_message?: string | null
    available_from_current_pay_period?: number | null
    available_from_previous_pay_period?: number | null
    available_to_transfer?: number | null
    balance_reasons?: PaydaysBalanceReason[] | null
    balance_updated?: boolean | null
    days_until_payday?: number | null
    employee_contract_id?: string | null
    employee_transfer_limits?: PaydaysEmployeeTransferLimits
    employer_fee?: number | null
    employer_fee_instant?: number | null
    furlough_minimum_available?: number | null
    gross_available?: number | null
    gross_earned?: number | null
    hours_worked?: number | null
    intercom_info: {
      available_to_transfer?: number | null
      current_limit_remaining?: number | null
      current_num_transfers?: number | null
      current_transferred?: number | null
      employee_bank?: string | null
      employee_code?: string | null
      gross_available?: number | null
      hourly_or_salary?: string | null
      max_transfer_percent?: number | null
      max_transfers_month?: number | null
      min_transfer_balance?: number | null
      monthly_transfer_limit?: number | null
      next_paycheck_date?: string | null
      num_transfers_this_period?: number | null
      pay_accrual_end?: string | null
      pay_accrual_start?: string | null
      pay_schedule_type?: string | null
      salary?: null | null
      state?: string | null
      total_debt?: number | null
      total_num_transfers?: number | null
      total_outstanding?: number | null
      total_transferred?: number | null
      transfers_disabled?: false | null
      [key: string]: unknown
    }
    last_paycheck_date?: string | null
    limit_remaining?: number | null
    max_transfer_percent?: number | null
    max_transfers_month?: number | null
    min_transfer_balance?: number | null
    monthly_transfer_limit?: number | null
    next_paycheck_date?: string | null
    num_transfers?: number | null
    num_transfers_this_period?: number | null
    open_pay_period_breakdown?: Record<string, OpenPayPeriodBreakdown[]>
    pay_accrual_end?: string | null
    pay_accrual_start?: string | null
    pay_schedule_id?: string | null
    pay_schedule_lock_date?: null | null
    pay_schedule_paycheck_date?: string | null
    pay_schedule_start_date?: string | null
    shifts_worked?: number | null
    total_balance?: number | null
    total_debt?: number | null
    total_outstanding?: number | null
    transfer_button_text?: string | null
    transfer_fee?: number | null
    transfer_fee_instant?: number | null
    transfer_fee_message?: string | null
    transferred?: number | null
    transfers_disabled?: false | null
    transfers_this_period?: number | null
    within_arrears_period?: false | null
    [key: string]: unknown
  }
}
export interface PaydaysPayment {
  payment_id?: string | null
  created_at?: string | null
  completed_at?: string | null
  net_amount?: number | null
  gross_amount?: number | null
  currency?: string | null
  payment_source_id?: string | null
  fee?: number | null
}

export interface PaydaysPaymentSource {
  name?: string | null
  banking_provider?: string | null
  payment_source_id?: string | null
  currency?: string | null
  external_ledger_id?: string | null
  company_id?: string
}

export interface PaydaysPaymentElection {
  order: number
  country: string
  payment_type: string
  bank_account_name?: string | null
  bank_account_type: string
  bank_account_number: string
  bank_routing_number: string
  distribution_amount?: number | null
  distribution_balance?: boolean | null
  distribution_percentage?: number | null
}

export interface PaydaysDPEOverage {
  dpe_overage_id: string
  amount: number
  mitigation_entity: string
  repaid_on: string
  sync_result_id: string
}

export interface PaydaysEmployeePaymentElection {
  payment_election_id: string
  employee_id: string
  created_at: string
  updated_at: string
  details: PaydaysPaymentElection[]
  properties?: object | null
}

export interface PaydaysEmployeePaymentElectionResonse {
  ok: boolean
  payment_elections?: PaydaysEmployeePaymentElection | null
  update_in_progress: boolean
  maximum_payment_elections?: number | null
  valid: boolean
  valid_count?: boolean | null
  valid_balance?: boolean | null
  valid_percentage?: boolean | null
}

export interface PaydaysCredit {
  expires_at?: string | null
  granted_on?: string | null
  credit_id?: string | null
  balance?: number | null
  properties?: {
    root_user_id?: string | null
    reason?: string | null
  }
}

export interface PaydaysSearchResult {
  entity: "company" | "employee"
  name: string
  primary_key: string
  priority: number
  extra_info?: null | string
}

export interface PaydaysSearchResponse {
  hits: PaydaysSearchResult[]
}

export interface PaydaysClonePayScheduleResponse {
  new_pay_schedule_id: string
}

export type PaydaysSemaphore = {
  expiration_ts: string
  grant_id: string
}

export interface PaydaysCron {
  crontab: string
  is_active: boolean
  job_id: string
  job_name: string
  last_completed_at: null | string
  last_started_at: string
  properties: unknown
  semaphore: PaydaysSemaphore[]
  task_name: string
}

export interface PaydaysOutstandingCharge {
  employee_id: string
  employee_name: string
  company_id: string
  company_name: string
  employee_state: string
  employee_code: string
  employee_start_ts: string
  employee_termination_ts: string
  enrollment_ts: string
  disabled_ts: string
  most_recent_salary: string
  transfer_id: string
  transfer_ts: string
  currency: string
  principle: number
  fee: number
  principle_outstanding: number
  fee_outstanding: number
}

export interface PaydaysCompanyJob {
  company_id: string
  created_at: string
  hourly_rate: number
  job_code: string
  job_id: string
  multiplier?: string | null
  name?: string | null
  properties: unknown
  updated_at: string
}

export type ShareCodeActionOptions = "APPROVE" | "REJECT" | "NO_ACTION"

export interface ShareCodeReport {
  user_id: string
  person_id: string
  full_name: string
  work_email: string
  phone?: string
  nationality: string
  dob: string
  due_diligence_check_id: string
  share_code?: string
  share_code_expiry_date?: string | null
  share_code_indefinite?: boolean
  share_code_action?: ShareCodeActionOptions
  rtw_file?: string
}

export interface PaydaysArticleProperties {
  slug: string
  type: "article" | "category" | "home"
  viewable?: string
  related_article_ids?: number[]
  description?: string
}

export interface PaydaysArticle {
  article_id: string
  contents: string
  properties: PaydaysArticleProperties | null
  title: string
  created_at: string
  updated_at: string
  reverse_related_articles?: {
    related_article_ids?: [
      {
        title: string
        article_id: string
      }
    ]
  }
}

export interface PaydaysContextInput {
  employee_id?: string
  user_id?: string
  native_device_id?: string
}

export type PaydaysContext = Record<string, unknown>

export interface PaydaysContextAndInput {
  data: PaydaysContext
  input: PaydaysContextInput
}

export interface PaydaysExpense {
  expense_id: string
  employee_id: string
  full_name: string
  company_id: string
  company_name: string
  amount_pence: number
  expected_date: string
  created_at: string
  updated_at: string
  date_matched: string
  company_state: string
}
export interface PaydaysEmployment {
  banking_info?: null
  company_id: string
  created_at?: string | null
  current_state: string
  default_wagerate: 0
  employee_code: string
  employee_id: string
  full_name?: string | null
  integration_properties: unknown
  invited_at?: string | null
  last_login_at?: string | null
  max_transfer_percent: number
  max_transfers_month?: string | null
  min_transfer_balance?: string | null
  monthly_transfer_limit?: string | null
  name: string
  pay_schedule_id: string
  photo_url?: string | null
  properties: {
    is_proto_employee?: boolean
    beneficiary_ledger_id?: string | null
    [key: string]: unknown
  }
  salary_properties: unknown
  started_on?: string | null
  terminated_at?: string | null
  updated_at?: string | null
  work_email?: string | null
  work_phone?: string | null
}

export interface PaydaysRegistration {
  registration_id: string
  full_name?: string | null
  email: string
  ip: string
  employee_id?: string | null
  properties?: unknown
  company_id?: string | null
  company_name?: string | null
  created_at?: string | null
  matched_at?: string | null
  rejected_at?: string | null
  updated_at?: string | null
}

export interface PaydaysJsonTemplate {
  json_template_id?: string
  created_by_user_id?: string
  created_at?: string
  updated_at?: string
  name: string
  properties: PaydaysCompanyProperties
}

export interface PaydaysCompanyJsonTemplate extends PaydaysJsonTemplate {
  entity_type: "company"
}

export interface PaydaysEnrollmentIssue {
  company_id: string
  company_name: string
  created_at: string
  employee_id: string
  employee_name: string
  is_resolved: boolean
  notes?: string
  pending_since: string
  reason: string
  root_user_id?: string
  updated_at: string
  username?: string
}

export interface PaydaysCampaign {
  code?: string | null
  created_at: string
  description?: string | null
  email_campaign_id: string
  email_template_id: string
  html: string
  is_active: boolean
  plaintext: string
  properties: {
    send_before_utc?: string
    send_after_utc?: string
    weekdays_only?: boolean
    companies?: string[]
    divisions?: "{}"
    filters: []
    groups: []
  }
  segment_id: string
  sql: string
  subject: string
  template_id: string
  updated_at: string
}

export interface PaydaysSegmentPropertiesFilter {
  id: string
  name: string
  description: string
  values: (string | boolean | string[])[]
  types: string[]
  prepend?: string
  append?: string
  data:
    | {
        display?: string
        key?: string
        source: string
      }
    | string[]
}

export interface PaydaysSegmentPropertiesTestGroup {
  group_id: number
  name: string
  weighting: string
}

export interface PaydaysSegment {
  created_at: string
  description: string
  properties: {
    companies?: string[]
    divisions?: "{}" | string[]
    filters?: PaydaysSegmentPropertiesFilter[] | null
    groups?: PaydaysSegmentPropertiesTestGroup[] | null
  }
  segment_id: string
  sql: string
  updated_at: string
}

export interface PaydaysSegmentDependents {
  dialogs: Pick<PaydaysDialog, "in_app_dialog_id" | "title">[]
  email_campaigns: Pick<PaydaysCampaign, "email_campaign_id" | "subject">[]
}

export interface PaydaysTrial {
  assignment_seed: number
  code: string
  created_at: string
  eligibility: {
    sql: string
  }
  enrollment_rate: number
  enrollment_seed: number
  experiment_id: string
  is_enrolling: boolean
  name: string
  properties: {
    subject_entity: string
  }
  updated_at: string
}

export interface PaydaysSegmentTestData {
  msg?: string | null
  data: Pick<PaydaysEmployee, "employee_id" | "full_name">[]
}

export interface PaydaysTreatment {
  code: string
  created_at: string
  experiment_id: string
  is_control: boolean
  name: string
  properties: unknown
  treatment_id: string
  updated_at: string
  weight: number
}

type PaydaysLinkedTemplate = {
  email_subject_line: string
  email_template_id: string
  template_id: string
  treatment_id: string
}

export interface PaydaysLinkedTrial {
  code: string
  experiment_id: string
  treatment_templates: PaydaysLinkedTemplate[]
}

export interface PaydaysOptout {
  banking_info?: unknown
  company_id?: string | null
  company_name?: string | null
  completed_at: null
  created_at?: string | null
  current_state: string
  default_wagerate: null
  e_properties?: {
    available_to_transfer?: number
    [key: string]: unknown
  }
  eid: string
  employee_code: string
  employee_id: string
  employee_optout_id: string
  full_name: string
  initiated_at: string
  initiated_by_user_id: string
  initiated_by_username: string
  integration_properties?: unknown
  invited_at: string
  last_login_at: string
  opted_back_in_at?: string | null
  optout_reason?: string | null
  pay_schedule_id?: string | null
  photo_url?: string | null
  properties?: string | null
  salary_properties: unknown
  started_on?: string | null
  terminated_at?: string | null
  updated_at?: string | null
  work_email?: string | null
  work_phone?: string | null
}

export interface PaydaysSicknessInsuranceReport {
  PartnershipID: string
  PolicyID: string
  RcptID: string
  RcptIssuanceType: string
  RcptIssuanceDate: string
  RcptEffectiveStartDate: string
  RcptEffectiveEndDate: string
  RcptCoverageCode: string
  RcptCoverageGWPExcTaxes: string
  RcptCoverageTaxes: string
  RcptCoverageContributions: string
  RcptCoverageGWPIncTaxes: string
  RcptCoverageCommissions: string
  RcptCurrency: string
  RcptPaymentStatus: string
}

export interface PaydaysSicknessSuspendResume {
  policy_id: string
  comment?: string
  disable_email?: boolean
}
export interface PaydaysSicknessCancel {
  effective_from?: string | null
  cancellation_type: string
  cancellation_note: string
}
export interface PaydaysSicknessClaimsPayment {
  claim_number: string
  policy_id: string
  amount_minor: number
  to_id?: string
}

export enum SicknessInsurancePolicyStatus {
  ACTIVE = "ACTIVE",
  AWAITING_POLICY_SETUP = "AWAITING_POLICY_SETUP",
  FAILED_POLICY_SETUP = "FAILED_POLICY_SETUP",
  FAILED_INITIAL_PAYMENT = "FAILED_INITIAL_PAYMENT",
  AWAITING_INITIAL_PAYMENT = "AWAITING_INITIAL_PAYMENT",
  ADVANCE_PAYMENT_MISSED = "ADVANCE_PAYMENT_MISSED",
  UNPAID = "UNPAID",
  CANCELLED = "CANCELLED",
  CANCELLING_END_OF_PERIOD = "CANCELLING_END_OF_PERIOD",
  CLAIMING = "CLAIMING",
  EXPIRED = "EXPIRED",
  SUSPENDED = "SUSPENDED",
  UNKNOWN = "UNKNOWN",
}
export type sicknessInsuranceQuote = {
  days_covered: number
  daily_claim_amount_minor: number
  monthly_cost_minor: number
  monthly_claim_amount_minor: number
  quote_id: string
}
type sicknessDocuments = {
  sickness_terms_and_conditions: string
  sickness_policy_information: string
}

type PolicySchedule = {
  payment_at: string
  period_end: string
  period_start: string
}
export interface PaydaysSicknessPolicy {
  policy_id: string
  external_policy_id: string
  user_id: string
  cost_per_month_gbp_minor: number
  status: SicknessInsurancePolicyStatus
  payment_method?: "STRIPE" | "SALARY_DEDUCTED"
  last_payment_at: string
  next_payment_at: string
  claim_per_day_gbp_minor: number
  claim_per_month_gbp_minor: number
  max_cover_days: number
  cover_starts_at: string
  cover_ends_at: string
  cancelled_at: string
  claim_starts_at: string
  claim_ends_at: string
  excess_days: number
  moratory_days: number
  underwriter: string
  schedule: PolicySchedule[]
  claiming: boolean
  suspended: boolean
  period_end: string
  period_start: string
  cover_period_idx: string
  invoiced_at: string
  quote?: sicknessInsuranceQuote
  documents: sicknessDocuments
  stripe_subscription_id?: string
  cancellation_note?: string
  cancellation_type?: string
}

export interface PaydaysEmailOptout {
  optout_id: string | null
  company_id?: string | null
  email: string | null
  created_at: string | null
  updated_at: string | null
  requested_at: string | null
  confirmed_at?: string | null
  confirmation?: {
    ip_address?: string | null
    user_agent?: string | null
  }
  method: "WEBFORM"
  notes?: string | null
  properties?: {
    lang?: string | null
    ip_address?: string | null
    user_agent?: string | null
  }
}

export interface PaydaysSettleTransfersResponse {
  failed_transfers?: {
    failure_reason: string
    transfer_id: string
  }[]
  ok: boolean
}

export interface PaydaysDevice {
  device_id: string
  device_platform: string
  native_device_id: string
  device_reported_model: string
  last_seen_system_version: string
  created_at: string
  updated_at?: string | null
  properties: unknown
  last_seen_app_version?: string | null
}

export interface PaydaysDeviceExperiment {
  active: boolean
  assigned_at: string
  experiment_code: string
  experiment_name: string
  treatment_code: string
  treatment_name: string
  properties?: {
    [key: string]: unknown
  }
}

export interface PaydaysUserDevice {
  device_id: string
  user_id: string
  user_device_created_at?: string | null
  user_device_last_seen_at?: string | null
  device_platform?: string | null
  native_device_id?: string | null
  device_reported_model?: string | null
  last_seen_system_version?: string | null
  device_created_at?: string | null
  device_updated_at?: string | null
  properties?: unknown | null
  last_seen_app_version?: string | null
}

export interface PaydaysUserAgreement {
  agreement_id: string
  accepted_at: string
  code: string
  version: string
  contents_url: string
  ip_address: string
  user_agent: string
}

interface DialogButton {
  page?: string
  subpage?: string
  text: string
}

export interface PaydaysDialog {
  button_text: string
  buttons: DialogButton[] | null
  company_id: string
  content: string
  created_at: string
  expires_at: string | null
  img_url: string
  in_app_dialog_id: string
  name: string | null
  priority: number
  properties?: {
    [key: string]: unknown
    is_live?: boolean
    dialog_type?: "html" | "survey" | "cta"
    dialog_button_text?: string
    typeform_survey_id?: string
    query_params?: string[]
    cta_link?: string
  } | null
  title: string
  updated_at: string
  segment_id?: string | null
  segment_description?: string | null
  segment_properties?: Record<string, unknown> | null
  sql?: string | null
  rules?: {
    group_id?: string | null
    segment_id?: string | null
  } | null
}

export interface PaydaysLedger {
  employee_id: string | null
  banking_provider: string
  beneficiary_id?: string | null
  ledger_id?: string | null
  properties?: {
    beneficiary_holder?: string | null
    ledger_meta?: string | null
  } | null
}

export interface PaydaysElevation {
  elevation_id: string
  user_id?: string | null
  device_id?: string | null
  username?: string | null
  authenticated?: boolean | null
  elevation_strategy?: string | null
  elevation_strategy_public_properties?: {
    [key: string]: unknown
  } | null
  requested_at?: string | null
  attempts?: number | null
  finalised_at?: string | null
  was_correct: boolean | null
  properties?: {
    [key: string]: unknown
  } | null
}

export interface PaydaysEmployeeConfiguration {
  [key: string]: unknown
}

export interface PaydaysFeatureFlagConfiguration {
  source: string
  entity_id: string | undefined
  name: string | undefined
  value: unknown
}

export interface PaydaysCommsEventLog {
  event_time: string
  event: string
}

export interface PaydaysResetResponse {
  ok: boolean
  new_employee_id?: string
  new_user_id?: string
}

export interface PaydaysRctaExperiment {
  EXPERIMENT_ID: string
  EXPERIMENT_CODE: string
  EXPERIMENT_NAME: string
  EXPERIMENT_CREATED_AT: string
  TREATMENTS: number
  ASSIGNED_SUBJECTS: number
  FIRST_ASSIGNED_AT?: string | null
  LAST_ASSIGNED_AT?: string | null
}

export interface PaydaysRctaTreatment {
  EXPERIMENT_ID: string
  EXPERIMENT_CODE: string
  EXPERIMENT_NAME: string
  TREATMENT_ID: string
  TREATMENT_CODE: string
  TREATMENT_NAME: string
  EXPERIMENT_CREATED_AT: string
  SUBJECT_ENTITY: string
  IS_CONTROL: boolean
  TREATMENT_PROPERTIES: object
  ASSIGNED_SUBJECTS: number
  FIRST_ASSIGNED_AT: string | null
  LAST_ASSIGNED_AT: string | null
  [key: string]: number | string | boolean | object | null
}

export interface PaydaysSalaryDetectionPresentedItem {
  created_at: string
  score: number
  date: string
  amount: number
  name: string
  transaction_id: string
  idField: number
}

export interface PaydaysSalaryDetectionSelectedItem {
  from_date: string
  to_date: string
  date: string
  amount: number
  name: string
  transaction_id: string
  idField: number
}

export interface PaydaysSalaryDetectionConnectedItem {
  bank_name: string
  mask: string
  display_name: string
  sort_code: string
  connected_at: string
  logo_url: string
  idField: number
}

export interface PaydaysSalaryDetectionCollegueChoiceItem {
  descriptor: string
  employee_id: string
  idField: number
}

export interface PaydaysSalaryDetection {
  presented: PaydaysSalaryDetectionPresentedItem[]
  selected: PaydaysSalaryDetectionSelectedItem[]
  connected: PaydaysSalaryDetectionConnectedItem[]
  colleague_choices: PaydaysSalaryDetectionCollegueChoiceItem[]
}

export interface PaydaysCard {
  user_id: string
  account_id: string
  card_id: string
  card_provider: string
  card_token: string
  pan: string
  expiration_date: string
  created_at: string
  updated_at: string
  first_name: string
  last_name: string
  is_active: boolean
  properties?: {
    employee_id?: string
    verified_at?: string
    card_details?: object
    error_count?: number
  }
}

export interface PaydaysCardItem {
  user_id: string
  account_id: string
  card_id: string
  card_provider: string
  card_token: string
  pan: string
  expiration_date: string
  created_at: string
  updated_at: string
  first_name: string
  last_name: string
  is_active: boolean
  properties?: {
    error_count?: number
  }
  verified_at?: string
}

export interface PaydaysCardClearResponse {
  message: string
  ok: boolean
}

export interface PaydaysPlaidTransaction {
  transaction_id: string
  user_bank_account_id: string
  account_id: string
  amount: string
  iso_currency_code: string
  category: string[]
  date: string
  datetime: string
  authorized_date: string
  authorized_datetime: string
  name: string
  normalized_name: string
  merchant_name: string
  created_at_utc: string
}

export interface PaydaysCompanyOverpaymentInvoice {
  company_id: string
  name: string
  stripe_customer_id?: string
  stripe_invoice_id?: string
  paid: boolean
  status: string
  invoice_number?: string
  total: number
}

export interface PaydaysJobBoardCompanyCandidate {
  company_id: string
  name: string
}

export interface PaydaysJobBoardCompany {
  job_company: string
  candidates: PaydaysJobBoardCompanyCandidate[]
}

export interface PaydaysJobBoardCompanyMapping {
  company_id: string
  job_company: string
}

export interface InternalJobRole {
  internal_job_role_id: string
  role: { [key: string]: string }
  description: string
  worker_rate: number
  client_rate?: number | null
  created_at: string
  updated_at: string
  properties: object
}

export interface ExternalJobRole {
  external_job_role_id: string
  role: string
  internal_job_role_id: string | null
  company_id: string
  created_at: string
  updated_at: string
  properties: { dismissed: boolean }
  company_name: string
}

export interface PaydaysEmployeeDPEAdvanceLog {
  employee_code: string
  advance_id: string
  amount_fee: number
  amount_net: number
  amount_gross: number
  amount_unprocessed: number
  bank_account_number: string
  bank_routing_number: string
  properties?: {
    savings_amount?: number
    bank_name?: string
  }
  created_at: string
  company_id: string
}

export interface KYCFormData {
  [key: string]: {
    value: string
    is_verified: boolean
    collected_from: string
  }
}
export interface OFACData {
  name: {
    first: string
    last: string
  }
  requestId: string
}

export interface PaydaysSavingsAccountEmployeeApplications {
  savings_account_application_id: string
  kyc_submitted: KYCFormData
  kyc_profile: KYCFormData
  ofac_status: string
  ofac_properties: OFACData
  kyc_status: string
  kyc_request: { [key: string]: string }
  kyc_response: { [key: string]: string }
  savings_status: "SUCCESS" | "FAILED" | "PENDING"
}

export interface PaydaysSavingsAccount {
  savings_account_id: string
  employee_id: string
  currency: string
  name: string
  photo_url: string
  target_amount: number
  target_date: string
  amount_per_paycheck: number
  created_at: string
  updated_at: string
  opened_at: string
  closed_at: string | null
  category: string
  due_diligence_check_id: string
  balance: number
  properties: object
  banking_properties: object
}

export interface PaydaysKycEmployeeManualAddressVerification {
  verification_type: string
  address_1: string
  address_2: string
  city: string
  state: string
  postal_code: string
  document_type: string
  document_number: string
  expiry_date: string
  notes: string
  first_name_matches: boolean
  last_name_matches: boolean
  dob_matches: boolean
}

export interface CardEvent {
  event: object
  type: string
}

export interface CardTransaction {
  posting_id: string
  journal_entry_id: string
  amount: number
  user_id: string
  transaction_type: string
  created_at: string
  committed_at: string
  journal_props: {
    tx_type: string
    initial_event_token: string
  }
  events?: [CardEvent]
}

export interface QueuedCardStatement {
  account_id: string
  user_id: string
  start_date: string
  end_date: string
}

export interface CardStatement {
  statement_id: string
  user_id: string
  account_id: string
  created_at: string
  start_date: string
  end_date: string
  start_balance: number
  end_balance: number
  minimum_payment_owed: number
  minimum_payment_paid: number
  minimum_payment_due: string
  pdf_link: string
  properties: object
}

export interface CardStatementDoc {
  statement_file: string
}

export interface CreditAccountAgentNotes {
  [note_id: string]: {
    note: string
    author: string
    created_at: string
    updated_at?: string
    change_log?: object
  }
}

export interface AddCreditAccountAgentNoteRequest {
  note: string
}

export interface UpdateCreditAccountAgentNoteRequest {
  note: string
  note_id: string
}

export interface CreditStatutoryNotices {
  notices: [
    {
      user_notice_id: string
      user_id: string
      payment_account_id: string
      notice_type: "nosia" | "snosia" | "nod"
      created_at: string
      due_at: string
      content_url: string
      properties: Record<string, unknown>
    }
  ]
}

export interface UpdateCreditStatutoryNoticesRequest {
  user_notice_id: string
  letter_posting_info: string
  letter_sent_at: string
}

export interface CreditStatutoryLetterDoc {
  letter_file: string
}

export interface AgreementDoc {
  base64data: string
}

export interface CardReverseTransaction {
  taget_id: string
}

export interface CardUpdateStatus {
  state: "ACTIVE" | "SUSPENDED" | "TERMINATED"
}

type SDLRepaymentDates = string[]

type SDLRepaymentBreakdown = {
  [date: string]: {
    carried_balance: number
    charged_interest: number
    days: number
    interest_balance: number
    irate: number
    repayment: number
  }
}

type SDLRepaymentCalculation = {
  breakdown: SDLRepaymentBreakdown
  repayment_dates: SDLRepaymentDates
  total_repayment: number
}

export interface AccountBalance {
  fees: number
  interest: number
  principal: number
  principal_commited: number
  overall: number
  overall_commited: number
  currency: string
  credit_limit?: number
  loan_amount?: number
  repayment_calculation?: SDLRepaymentCalculation
}

export interface SDLStatus {
  status: "ACTIVE" | "NONE"
}

export interface SimulateRepayment {
  amount_minor: number
  user_id: string
  created_at: string
}

export interface SimulateTermination {
  user_id: string
  terminated_at: string
}

export interface SimulateActivation {
  user_id: string
}

export interface SimulateWebhookProcessing {
  event_type: string
  amount_minor: number
  user_id: string
  created_at: string
}

export interface PaymentCard {
  card_status: string
  created_at: string
  properties: {
    status_update_author: string
    is_virtual: boolean
  }
  currency: string
}

export interface PaymentAccount {
  account_id: string
  payment_ref: string
  date_created: string
  properties: {
    interest_rate?: number
    simple_interest_rate?: number
    credit_limit_minor: number
    repayment_settings: {
      direct_debit_mandate_id?: string
    }
    statement_settings: {
      statement_gen_type: string
      statement_gen_arg: number
    }
  }
}

export interface Person {
  first_name: string
  last_name: string
  created_at: string
  updated_at: string
  dob: string
  address: object
  properties: {
    onfido_applicant_id: string
  }
}

export interface DueDiligenceCheck {
  due_diligence_check_id: string
  person_id: string
  created_at: string
  updated_at: string
  external_check_id: string
  status: string
  result: string
  properties: {
    onfido_applicant_id: string
  }
  type: string
  due_diligence_monitor_id: string
}

export interface IdCheck {
  id_check_id: string
  user_id: string
  created_at: string
  updated_at: string
  external_id: string
  provider: string
  status: string
  properties: object
  check_type: string
  failure_reasons: string[]
}

export interface DueDiligenceStatus {
  active_monitors: [DueDiligenceMonitor]
  latest_checks: { [key: string]: DueDiligenceCheck }
}

export interface DueDiligenceMonitor {
  due_diligence_monitor_id: string
  person_id: string
  created_at: string
  deleted_at: string
  external_monitor_id: string
  properties: object
}

export interface DirectDebitMandate {
  direct_debit_mandate_id: string
  user_id: string
  external_mandate_id: string
  status: string
  created_at: string
  updated_at: string
  banking_provider: string
  provider_entity: Record<string, Record<string, unknown>>
  properties: Record<string, Record<string, unknown>>
}

export interface DirectDebitCollection {
  direct_debit_collection_id: string
  user_id: string
  direct_debit_mandate_id: string
  external_collection_id: string
  amount_minor: number
  status: string
  created_at: string
  updated_at: string
  banking_provider: string
  provider_entity: Record<string, Record<string, unknown>>
  properties: Record<string, Record<string, unknown>>
}

export interface AccountApplicationsResponse {
  latest?: AccountApplication
  all?: AccountApplication[]
  eligibility?: {
    is_eligible: boolean
    checks: Record<string, { eligible: boolean }>
  }
}

export interface AccountApplicationRequest {
  account_application_id: string
}

export interface AccountApplication {
  account_application_id: string
  product_type: string
  user_id: string
  underwriting_checks: Record<string, unknown>
  underwritten_at: string
  id_checks: Record<string, Record<string, unknown>>
  id_verified_at: string
  accepted_terms: object
  accepted_terms_at: string
  state: string
  created_at: string
  completed_at: string
  properties: Record<string, Record<string, unknown>>
}

export interface AccountApplicationUpdateRequest {
  account_application_id: string
  decision_type: string
  decision: string
  reason: string
  credit_details?: {
    credit_limit: number
    apr_tier: string
  }
  sdl_details?: {
    apr_tier: string
    max_loan_amount: number
    min_monthly_repayment_amount: number
    max_monthly_repayment_amount: number
  }
  kyc_details?: {
    fraud?: boolean
    peps_and_sanctions?: boolean
    id?: boolean
    address?: boolean
    pep?: boolean
    heightened_monitoring?: boolean
    link_to_docs?: string
  }
  schedule_settings?: {
    gen_type: string
    gen_arg?: number
    business_day_arg?: string | null
  }
}

export interface AccountApplicationAPRs {
  apr_options: Record<string, number>[]
}

export interface RootAction {
  root_action_id: string
  requested_at: string
  requestor_id: string
  reviewed_at: string
  reviewer_id: string
  execution_result: string
  approved: boolean
  context: {
    url: string
    method?: string
    body?: {
      entity?: string
      properties?: {
        note?: string
      }
      transform_definition?: string
      transform_name?: string
      version?: number
    }
  }
  properties: {
    category: string
  }
}

export interface RootActionReview {
  reason: string | undefined
  approved: boolean
}

export interface ManualTransaction {
  banking_provider: string
  purpose: string
  type: string
  from_id: string
  to_id: string
  amount: number
  currency: string
  reference?: string
  transfer_id?: string
  parent_id?: string
  employee_id?: string
}

export interface PaydaysVoucherRetailer {
  code: string
  name: string
  percentage_user: number
  percentage_provider: number
  logo_url: string
  expiry?: string
  values_config: {
    lower: number
    upper: number
    minor_unit: number
  }
  enabled: boolean
  properties: {
    visible: boolean
    categories: string[]
    additional_names: string[]
  }
}

export interface PaydaysFinancialHealthScore {
  created_at: string
  employee_id: string
  form_response: { [key: string]: number[] }
  score: number
  score_id: string
  typeform_response_id: string
}

export interface PaydaysOpenBankingTransaction {
  account_id: string
  amount: number
  categories: {
    [key: string]: {
      slug: string
      confidence: string
    }
  }
  created_at: string
  currency: string
  description: string
  external_transaction_id: string
  merchant_id: string
  periodicity: unknown
  posted_at: string
  processor_id: string
  status: string
  transaction_id: string
  type: string
  merchant_name: string
}

export interface AllowListValue {
  allow_list_id: string
  allow_value: string
  company_id: string
  allow_key: string
  created_at: string
}

export interface DebitcardUS {
  ws_card_id: string
  user_id: string
  account_id: string
  card_status: string
  card_api_provider: string
  network: string
  external_card_id: string
  fulfillment_status: string
  currency: string
  created_at: string
  updated_at: string
}

export interface DebitcardApplicationUS {
  card_application_id: string
  product_type: string
  user_id: string
  account_id: string
  underwritten_at: string
  id_checks: Record<string, unknown>
  id_verified_at: string
  accepted_terms_at: string
  status: string
  created_at: string
  completed_at: string
  internal_status: string
}

export interface DebitcardApplicationHistoryUS {
  card_application_id: string
  status: string
  created_at: string
}

export interface DebitcardStatusHistoryUS {
  ws_card_id: string
  status: string
  created_at: string
}

export type DebitcardUSStatus = "ACTIVE" | "SUSPENDED" | "TERMINATED"

export interface PaymentAccountUS {
  account_id: string
  user_id: string
  account_type: string
  date_created: string
  closed_at: string
  close_pending_at: string
}

export interface ZellisZIPSortCodeResponse {
  ok: boolean
  sort_codes: object[]
  error?: string
}

export type ZellisZIPSortCode = {
  sort_code: string
  branch_detail_id: string
}

export interface ZellisZIPPayElementResponse {
  ok: boolean
  pay_elements: object[]
  error?: string
}

export type ZellisZIPPayElement = {
  code: string
  category: string
  description: string
}

export type ZellisZIPCredentialsTestResponse = {
  ok: boolean
  access_token?: string
  request_response?: string
  request_status_code?: string
  error?: string
}

export interface PaydaysCompanyDocument {
  code: string
  created_at: string
  deleted_at?: string | null
  document_id: string
  document_type_id: string
  entity: "companies"
  entity_id: string
  file_extension: "pdf"
  is_required: boolean
  metadata: Record<string, unknown>
  name: string
  original_filename: string
  properties: Record<string, unknown>
}

export interface PaydaysCompanyDocumentTypes {
  document_type_id: string
  code: string
  name: string
  valid_extensions: string[]
  is_required: boolean
  created_at: string
  updated_at: string
}

export interface PaydaysCompanyDocumentType {
  title: string
  description: string
  properties: Schema
  required?: string[]
}

export interface PaydaysCreateDocument {
  filename: string
  contents: ArrayBuffer // Base64
}

export interface PaydaysPatchCompanyDocument {
  document_id: string
  metadata: Record<string, unknown>
}

export interface PaydaysGetCompanyDocumentDownloadUrl {
  download_url: string
  original_filename: string
  file_extension: string
}
export interface PayDaysGetWeeklyPaySummaryShift {
  shift_id: string
  worked_on: string
  wages: number
  properties: {
    finalised_date?: string
    transfer_id?: string
  }
  payable: boolean
}

export interface PayDaysGetWeeklyPaySummary {
  company_id: string
  employee_id: string
  start_date: string
  end_date: string
  autostream_amount: number
  total_balance: number
  available_to_transfer: number
  total_value_of_shifts: number
  streamable_percentage: number
  streamable_value_of_shifts: number
  streamable_balance: number
  total_streamed: number
  balance_percentages: [number, number]
  shifts: [PayDaysGetWeeklyPaySummaryShift]
  weekly_pay_settings: PayDaysWeeklyPaySettings
}

export type TagEntities = "user"

export interface EntityTag {
  name: string
  follow_up_date?: string
  pending?: boolean
  info?: string

  change_log?: [
    {
      date: string
      author: string
      operation: string
      message?: string
      changes: { [key: string]: object }
    }
  ]
}

export interface EntityTagPayload extends Omit<EntityTag, "pending"> {
  reason?: string
}

export interface Tag {
  info_enum?: string[]
  name: string
  retired?: boolean
  requires_approval?: boolean
  display_on_header?: boolean
  style?: { [key: string]: string }
}

export interface UserTagsResponse {
  active: { [key: string]: EntityTag }
  inactive: { [key: string]: EntityTag }
  all: { [key: string]: EntityTag }
}

export interface TagListResponse {
  [key: string]: Tag
}

export interface PaydaysCompanyIntegrationTransforms {
  company_integration_transform_id: string
  transform_name: string
  entity: string
  version: number
  transform_definition: string
  properties: string
  created_at: string
  updated_at: string
}

export interface PaydaysLinkedIntegrations {
  name: string
  company_integration_id: string
  company_id: string
  partner: string
  is_linked: boolean
}

export interface PaydaysEntitySchema {
  employee: string[]
  shift: string[]
  absence: string[]
  job: string[]
}
export interface PaydaysCompanyIntegrationTransformsTest {
  data: [][]
}
