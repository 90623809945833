/* eslint sort-keys: "error" */
/* Some endpoints need trailing slashes to avoid getting a 308
 * redirect during the OPTIONS preflight request */

import type {
  IdCheck,
  PaydaysArticle,
  PaydaysCompanyDocumentType,
  PaydaysCompanyDocumentTypes,
  PaydaysCreateDocument,
  AccountBalance,
  CardUpdateStatus,
  SDLStatus,
  PayDaySettingsPatch,
  DueDiligenceCheck,
  DueDiligenceMonitor,
  DueDiligenceStatus,
  Person,
  QueuedCardStatement,
  SimulateActivation,
  SimulateRepayment,
  SimulateTermination,
  SimulateWebhookProcessing,
  RootAction,
  RootActionReview,
  PaymentCard,
  ManualTransaction,
  PaydaysSicknessPolicy,
  PaydaysSicknessClaimsPayment,
  CardStatementDoc,
  CreditAccountAgentNotes,
  AddCreditAccountAgentNoteRequest,
  UpdateCreditAccountAgentNoteRequest,
  CreditStatutoryNotices,
  UpdateCreditStatutoryNoticesRequest,
  CreditStatutoryLetterDoc,
  PaydaysCardClearResponse,
  PaydaysVoucherRetailer,
  PaydaysFinancialHealthScore,
  PaydaysOpenBankingTransaction,
  DebitcardUS,
  DebitcardApplicationUS,
  DebitcardApplicationHistoryUS,
  DebitcardStatusHistoryUS,
  PaymentAccountUS,
  PaydaysUserAgreement,
  GenericJSONSchemaProperty,
  PaydaysAbsences,
  PaydaysAddCompanyComment,
  PaydaysAddCompanyDivision,
  PaydaysAddPayrollReturnBankDetails,
  PaydaysAddPayScheduleResponse,
  PaydaysAllocateIntegrationShiftData,
  PaydaysBankingBeneficiary,
  PaydaysClonePayScheduleResponse,
  PaydaysCompany,
  PaydaysCompanyAdmin,
  PaydaysCompanyAdminResponse,
  PaydaysCompanyComment,
  PaydaysCompanyConfiguration,
  PaydaysCompanyDataSet,
  PaydaysCompanyDivision,
  PaydaysCompanyDivisionManager,
  PaydaysCompanyEmployeeSnapshot,
  PaydaysCompanyFile,
  PaydaysCompanyFourthEmployee,
  PaydaysCompanyIntegration,
  PaydaysCompanyPaySchedule,
  PaydaysCompanyProperties,
  PaydaysCompanySalaryActivity,
  PaydaysEmployeeConfiguration,
  PaydaysFeatureFlagConfiguration,
  PaydaysCorporateGroup,
  PaydaysCredit,
  PaydaysCron,
  PaydaysDPEOverage,
  PaydaysElevation,
  PaydaysEmailOptout,
  PaydaysEmailTemplate,
  PaydaysEmployee,
  PaydaysEmployeeBalance,
  PaydaysEmployeeBalanceHistory,
  PaydaysEmployeeDPEAdvanceLog,
  PaydaysEmployeeEmail,
  PaydaysEmployeeHistory,
  PaydaysEmployeeMicrodeposits,
  PaydaysEmployeePatch,
  PaydaysEmployeeSyncResult,
  PaydaysESRLookup,
  PaydaysFourthTable,
  PaydaysHistoryTransaction,
  PaydaysHypercareCheck,
  PaydaysInnoviseIntegrationBranchEntry,
  PaydaysIntegrationSFTPReportInfo,
  PaydaysIntegrationSyncResult,
  PaydaysIntegrationSyncResults,
  PaydaysLedger,
  PaydaysNewPaySchedule,
  PaydaysNextPaySchedule,
  PaydaysOnboardingWorkflow,
  PaydaysPaySchedule,
  PaydaysPayScheduleDate,
  PaydaysPayScheduleUsedByCompany,
  PaydaysPayment,
  PaydaysPaymentSource,
  PaydaysPropertyChangelog,
  PaydaysRPAData,
  PaydaysTestRPAData,
  PaydaysRootUser,
  PaydaysRota,
  PaydaysSalary,
  PaydaysSavingsAccountEmployeeApplications,
  PaydaysSearchResponse,
  PaydaysSegmentTestData,
  PayDaysSettings,
  PaydaysSettleTransfersResponse,
  PaydaysShift,
  PaydaysSmeComplianceStatus,
  PaydaysCompanyCreditLimit,
  PaydaysSmeRelevantPeople,
  PaydaysSmeSubscription,
  PaydaysCompanyOverpaymentInvoice,
  PaydaysSortcode,
  PaydaysSubscriptions,
  PaydaysTransaction,
  PaydaysTransfer,
  PaydaysUnsettledTransaction,
  PaydaysUpdateDefaultTransferLimits,
  PaydaysUser,
  PaydaysWebUser,
  Schema,
  PaydaysCompanyJob,
  PaydaysEmployment,
  PaydaysRegistration,
  PaydaysExpense,
  PaydaysAdminUser,
  PaydaysSalaryPayday,
  PaydaysCampaign,
  PaydaysDivision,
  PaydaysSegment,
  PaydaysSegmentDependents,
  PaydaysTrial,
  PaydaysTreatment,
  PaydaysTransferResponse,
  PaydaysEnrollmentIssue,
  PaydaysOptout,
  PaydaysLinkedTrial,
  PaydaysUserExperiment,
  PaydaysUserHistory,
  PaydaysEmailTemplateContent,
  PaydaysOutstandingCharge,
  PaydaysSalaryDisabled,
  PaydaysEmailTemplateRender,
  PaydaysMutationResponse,
  PaydaysGetRPARequestData,
  PaydaysUserDevice,
  PaydaysCompanyContact,
  PaydaysDialog,
  PaydaysUserEmail,
  PaydaysCommsEventLog,
  PaydaysUserLogin,
  PaydaysResetResponse,
  PaydaysRctaExperiment,
  PaydaysRctaTreatment,
  PaydaysSalaryDetection,
  PaydaysCompanySearch,
  PaydaysCompanyJsonTemplate,
  PaydaysCard,
  ACHDebitExclusionPhrase,
  ACHDebitInclusionPhrase,
  PaydaysPlaidTransaction,
  PaydaysSicknessInsuranceReport,
  PaydaysJobBoardCompany,
  PaydaysJobBoardCompanyMapping,
  CardTransaction,
  CardStatement,
  CardReverseTransaction,
  AccountApplicationsResponse,
  AccountApplicationRequest,
  AccountApplicationAPRs,
  AccountApplicationUpdateRequest,
  AllowListValue,
  PaydaysContextAndInput,
  PaydaysDevice,
  PaydaysDeviceExperiment,
  PaydaysIntegrationsLatestBucketFile,
  PaydaysCompanyDocument,
  PaydaysPatchCompanyDocument,
  PaydaysGetCompanyDocumentDownloadUrl,
  PaydaysEmployeePaymentElectionResonse,
  ZellisZIPPayElementResponse,
  ZellisZIPSortCodeResponse,
  PaydaysCompanyFundingEntity,
  PayDaysGetWeeklyPaySummary,
  ZellisZIPCredentialsTestResponse,
  PaydaysSavingsAccount,
  PaymentAccount,
  PaydaysDiscountRetailers,
  PaydaysDiscountsPromotedGet,
  PaydaysDiscountForRetailer,
  PaydaysDiscount,
  AgreementDoc,
  PaydaysDiscountsConfig,
  PaydaysDiscountsHomepageProductFeed,
  Tag,
  UserTagsResponse,
  TagListResponse,
  EntityTagPayload,
  TagEntities,
  ExternalJobRole,
  InternalJobRole,
  ShareCodeReport,
  RaisePortalUser,
  PaydaysCompanyIntegrationTransforms,
  PaydaysCompanyIntegrationTransformsTest,
  PaydaysLinkedIntegrations,
  DirectDebitMandate,
  DirectDebitCollection,
  PaydaysDiscountCategory,
  PaydaysEntitySchema,
  PaydaysDiscountCategoryHome,
  PaydaysDiscountCategoryCreatePayload,
} from "api"
import type { ICompanyRegistration } from "context/registerCompany/types"
import type { ProductType } from "query-hooks/useAccountBalance"
import { E } from "./generators"

export const auth = {
  adminLogin: () => "/auth/sudo",
  adminLogout: () => "/auth/logout",
  elevations: {
    byUserId: (userId: string) =>
      E("/auth/elevations", {
        user_id: userId,
      })
        ._get<PaydaysElevation[]>()
        .seal(),
  },
  googleTokenExchange: () => "/auth/google-token",
  impersonateAdmin: (id?: string) =>
    `/auth/impersonate/${id}`,
  me: () => "/auth/me",
  newRoot: () => "/auth/newroot",
  oauthLogin: () => "/auth/authenticate",
  oauthLoginGoogleUrl: () =>
    "/auth/authenticate/google/url",
  passwordReset: () =>
    E("auth/forgot")._put<{ is_valid: boolean }>().seal(),
}

export const images = {
  upload: () =>
    E("/images/upload/")
      ._post<{ https_path: string }>()
      .seal(),
}

export const absences = {
  byEmployeeId: (employeeId?: string, removed = false) =>
    E(
      `/absences/employee/${employeeId}`,
      removed
        ? {
            removed,
          }
        : undefined
    )
      ._get<PaydaysAbsences[]>()
      .seal(),
  byId: (absenceId?: string) =>
    E(`/absences/${absenceId}`)._delete<unknown>().seal(),
}

export const settings = {
  all: () =>
    E(`/settings/`)
      ._get<undefined, PayDaysSettings>()
      ._patch<PayDaySettingsPatch>()
      .seal(),
}

export const allowList = {
  all: () => E(`/allow_list/`)._post().seal(),
  byCompanyId: (companyId: string) =>
    E(`/allow_list/${companyId}`)
      ._get<AllowListValue[]>()
      ._delete()
      .seal(),
  wipe: (companyId: string) =>
    E(`/allow_list/${companyId}/wipe_allow_list`)
      ._delete()
      .seal(),
}

export const registrations = {
  all: () =>
    E("/registrations/")
      ._get<PaydaysRegistration[]>()
      .seal(),
  setup: () =>
    E("/sme/setup/b2b")
      ._post<ICompanyRegistration>()
      .seal(),
}

export const properties = {
  companyTemplates: () =>
    E("/properties/templates?entity=company")
      ._get<PaydaysCompanyJsonTemplate[]>()
      ._post<PaydaysCompanyJsonTemplate>()
      ._patch<PaydaysCompanyJsonTemplate>()
      .seal(),
}

export const raise = {
  actionShareCodesBulk: () =>
    E(`/due_diligence/bulk_action_share_codes`)
      ._put<ShareCodeReport[]>()
      .seal(),
  shareCodeReport: () =>
    E(`/talent_pool/reports/share_codes_requiring_action`)
      ._get<ShareCodeReport[]>()
      .seal(),
}

export const jobs = {
  all: () => E(`/jobs/`)._get<PaydaysCompanyJob[]>().seal(),
}

export const jobBoard = {
  all: () =>
    E(`/sme/jobs/admin/resolve`)
      ._get<PaydaysJobBoardCompany[]>()
      ._post<PaydaysJobBoardCompanyMapping>()
      .seal(),
}

export const jobRoles = {
  allInternalJobRoles: () =>
    E(`/job_roles/internal_job_roles`)
      ._get<InternalJobRole[]>()
      ._post<InternalJobRole>()
      .seal(),
  assignedExternalJobRoles: () =>
    E(`/job_roles/external_job_roles/assigned`)
      ._get<ExternalJobRole[]>()
      .seal(),
  bulkUpdateExternalJobRoles: () =>
    E(`/job_roles/external_job_roles/bulk_update`)
      ._put<ExternalJobRole[]>()
      .seal(),
  internalJobRoleKeys: () =>
    E(`/job_roles/internal_job_roles/keys`)
      ._get<string[]>()
      .seal(),
  singleInternalJobRole: (jobRoleId: string) =>
    E(`/job_roles/internal_job_roles/${jobRoleId}`)
      ._delete<InternalJobRole[]>()
      .seal(),
  unassignedExternalJobRoles: () =>
    E(`/job_roles/external_job_roles/unassigned`)
      ._get<ExternalJobRole[]>()
      .seal(),
}

export const overpayments = {
  all: () =>
    E(`/stripe/overpayments/invoices`)
      ._get<PaydaysCompanyOverpaymentInvoice[]>()
      .seal(),

  byCompanyId: (companyId?: string) =>
    E(`/stripe/overpayments/invoices/${companyId}`)
      ._get<PaydaysCompanyOverpaymentInvoice[]>()
      .seal(),

  issueForCompany: (
    companyId?: string,
    limit_to_disabled?: boolean | null
  ) => {
    const url = limit_to_disabled
      ? `/stripe/overpayments/${companyId}?limit_to_disabled=true`
      : `/stripe/overpayments/${companyId}`
    return E(url)._post().seal()
  },

  undoInvoice: (invoiceId?: string) =>
    E(`/stripe/overpayments/invoice/${invoiceId}`)
      ._delete()
      .seal(),
}

export const articles = {
  all: () =>
    E("/articles/")
      ._get<PaydaysArticle[]>()
      ._post<PaydaysArticle>()
      .seal(),
  byId: (articleId?: string) =>
    E(`/articles/${articleId}`)
      ._get<PaydaysArticle>()
      ._put<PaydaysArticle>()
      .seal(),
}

export const context = {
  example: () =>
    E("/articles/context")
      ._post<PaydaysContextAndInput>()
      .seal(),
}

export const expenses = {
  all: (starts?: string | null, ends?: string | null) =>
    E(`/reports/expenses`, {
      ends_on: ends,
      starts_on: starts,
    })
      ._get<PaydaysExpense[]>()
      .seal(),
  byId: (id?: string | null) =>
    E(`/expenses/${id}`)._get<PaydaysExpense>().seal(),
}

export const sicknessInsurance = {
  allPolicies: () =>
    E(`/sickness_insurance/root/policies`)
      ._get<
        PaydaysSicknessPolicy[],
        PaydaysSicknessPolicy[]
      >()
      .seal(),
  cancelPolicy: (id?: string) =>
    E(`/sickness_insurance/root/policies/${id}/cancel`)
      ._post()
      .seal(),
  closeClaim: (id?: string) =>
    E(`/sickness_insurance/root/policies/${id}/end-claim`)
      ._post()
      .seal(),
  openClaim: (id?: string) =>
    E(`/sickness_insurance/root/policies/${id}/open-claim`)
      ._post()
      .seal(),
  payClaim: (policy_id: string) =>
    E(`/sickness_insurance/claims`)
      ._post<PaydaysSicknessClaimsPayment>()
      .seal(),
  policyByUserId: (userId: string) =>
    E(`/sickness_insurance/root/policies/${userId}`)
      ._get<
        PaydaysSicknessPolicy[],
        PaydaysSicknessPolicy[]
      >()
      .seal(),
  report: (
    starts?: string | null,
    ends_on?: string | null
  ) =>
    E(`/sickness_insurance/reports/invoices`, {
      ends_on: ends_on,
      starts_on: starts,
    })
      ._get<PaydaysSicknessInsuranceReport[]>()
      .seal(),
  resumePolicy: (id?: string) =>
    E(`/sickness_insurance/root/policies/${id}/resume`)
      ._post()
      .seal(),
  suspendPolicy: (id?: string) =>
    E(`/sickness_insurance/root/policies/${id}/suspend`)
      ._post()
      .seal(),
}

export const directDebit = {
  collections: ({
    end_date,
    start_date,
    user_id,
  }: {
    user_id?: string
    start_date?: string
    end_date?: string
  }) =>
    E(`/banking/modulr/dd-collections`, {
      end_date,
      start_date,
      user_id,
    })
      ._get<DirectDebitCollection[]>()
      .seal(),
  mandate: (direct_debit_mandate_id: string) =>
    E(
      `/banking/modulr/dd-mandate/${direct_debit_mandate_id}`
    )
      ._get<DirectDebitMandate>()
      .seal(),
}

export const paymentAccounts = {
  application_cancellation: (
    user_id: string,
    product_type: string
  ) =>
    E(
      `/payment-accounts/${product_type}/applications/cancel`,
      {
        user_id,
      }
    )
      ._post<AccountApplicationRequest>()
      .seal(),
  application_withdrawal: (
    user_id: string,
    product_type: string
  ) =>
    E(
      `/payment-accounts/${product_type}/applications/withdraw`,
      {
        user_id,
      }
    )
      ._post<AccountApplicationRequest>()
      .seal(),
  applications: (
    product_type: string,
    latest?: boolean,
    user_id?: string,
    employee_id?: string
  ) =>
    E(`/payment-accounts/${product_type}/applications`, {
      employee_id,
      latest,
      user_id,
    })
      ._get<AccountApplicationsResponse>()
      .seal(),
  balance: (
    product_type: ProductType,
    user_id: string,
    breakdown: boolean,
    as_of: string | null
  ) =>
    E(`/payment-accounts/${product_type}/balance`, {
      as_of,
      breakdown,
      user_id,
    })
      ._get<AccountBalance>()
      .seal(),
  get: (user_id?: string | null, account_type = "credit") =>
    E(
      `/payment-accounts/${account_type}?user_id=${user_id}`
    )
      ._get<PaymentAccount>()
      .seal(),
  terminate: (
    user_id?: string | null,
    account_type = "credit"
  ) =>
    E(
      `/payment-accounts/${account_type}/terminate?user_id=${user_id}`
    )
      ._post()
      .seal(),
}

export const paymentCards = {
  agent_notes: (
    user_id: string,
    account_type: ProductType,
    account_id: string
  ) =>
    E(
      `/payment-accounts/${account_type}/notes/${account_id}`,
      { user_id }
    )
      ._get<CreditAccountAgentNotes>()
      ._post<AddCreditAccountAgentNoteRequest>()
      ._patch<UpdateCreditAccountAgentNoteRequest>()
      .seal(),
  agreement_doc: (
    card_type?: string,
    agreement_id?: string,
    user_id?: string,
    generic = true,
    accepted = false
  ) =>
    E(
      `/payment-accounts/${card_type}/agreements/${agreement_id}/pdf?user_id=${user_id}&generic=${generic}&accepted=${accepted}`
    )
      ._get<AgreementDoc>()
      .seal(),
  card: (user_id: string) =>
    E(`/payment-cards/credit/card`, { user_id })
      ._get<PaymentCard>()
      .seal(),
  credit_application_aprs: (
    user_id: string,
    type: string,
    application_id?: string
  ) =>
    E(
      `/payment-accounts/${type}/applications/apr-options`,
      { application_id, user_id }
    )
      ._get<AccountApplicationAPRs>()
      .seal(),
  credit_application_decision_update: (
    user_id: string,
    type: string
  ) =>
    E(
      `/payment-accounts/${type}/applications/update-decision`,
      {
        user_id,
      }
    )
      ._post<AccountApplicationUpdateRequest>()
      .seal(),
  replace: (user_id: string) =>
    E(`/payment-cards/credit/replace`, { user_id })
      ._post()
      .seal(),
  reverse_transaction: () =>
    E(`/payment-cards/credit/reverse-transaction`)
      ._post<CardReverseTransaction[]>()
      .seal(),
  simulate_activation: (
    employee_id?: string,
    user_id?: string
  ) =>
    E(`/payment-cards/simulate/activation`, {
      employee_id,
      user_id,
    })
      ._post<SimulateActivation>()
      .seal(),
  simulate_repayment: () =>
    E(`/payment-cards/simulate/repayments`)
      ._post<SimulateRepayment>()
      .seal(),
  simulate_termination: () =>
    E(`/payment-cards/simulate/termination`)
      ._post<SimulateTermination>()
      .seal(),
  simulate_webhook: () =>
    E(`/payment-cards/simulate/webhook_processing`)
      ._post<SimulateWebhookProcessing>()
      .seal(),

  statement_doc: (
    card_type?: string,
    statement_id?: string
  ) =>
    E(
      `/payment-accounts/${card_type}/statement-doc/${statement_id}`
    )
      ._get<CardStatementDoc>()
      .seal(),
  statement_generation: (user_id?: string) =>
    E(`/payment-cards/simulate/statement_generation`, {
      user_id,
    })
      ._post()
      .seal(),
  statement_queued: (user_id?: string) =>
    E(`/payment-accounts/credit/queued-statement`, {
      user_id,
    })
      ._get<QueuedCardStatement>()
      .seal(),
  statements: (card_type?: string, user_id?: string) =>
    E(`/payment-accounts/${card_type}/statements`, {
      user_id,
    })
      ._get<CardStatement[]>()
      .seal(),
  statutory_notice_letter_doc: (
    user_notice_id: string,
    user_id: string
  ) =>
    E(
      `/payment-accounts/notices/${user_notice_id}?user_id=${user_id}`
    )
      ._get<CreditStatutoryLetterDoc>()
      .seal(),
  statutory_notices: (
    user_id: string,
    product_type: string,
    notice_type: string
  ) =>
    E(
      `/payment-accounts/${product_type}/notices/${notice_type}?user_id=${user_id}`
    )
      ._get<CreditStatutoryNotices>()
      ._patch<UpdateCreditStatutoryNoticesRequest>()
      .seal(),
  transactions: (
    card_type?: string,
    from?: string | null,
    to?: string | null,
    pending?: boolean,
    user_id?: string,
    include_events?: boolean
  ) =>
    E(`/payment-accounts/${card_type}/transactions`, {
      from,
      include_events,
      pending,
      to,
      user_id,
    })
      ._get<CardTransaction[]>()
      .seal(),
  update_status: (user_id: string) =>
    E(`/payment-cards/credit/card-state`, { user_id })
      ._put<CardUpdateStatus>()
      .seal(),
}

export const sdl = {
  status: (user_id: string) =>
    E(`/payment-accounts/sdl/status`, { user_id })
      ._get<SDLStatus>()
      .seal(),
}

export const workplaceSavings = {
  transactions: (account_id: string | null) =>
    E(
      `/workplacesavings/${account_id}/transactions?pending=true`
    )
      ._get<CardTransaction[]>()
      .seal(),
}

export const optouts = {
  complete: (id?: string) =>
    E(`/useroptouts/${id}/complete`)._post().seal(),
  list: () =>
    E(`/useroptouts/list`)._get<PaydaysOptout[]>().seal(),
  optedin: () =>
    E(`/useroptouts/optedin`)
      ._get<PaydaysOptout[]>()
      .seal(),
  pending: () =>
    E(`/useroptouts/pending`)
      ._get<PaydaysOptout[]>()
      .seal(),
  ready: () =>
    E(`/useroptouts/ready`)._get<PaydaysOptout[]>().seal(),
}

export const emailOptouts = {
  byEmail: (email?: string) =>
    E(`/optouts/find?email=${email}`)
      ._get<PaydaysEmailOptout>()
      .seal(),
  reverse: (email?: string) =>
    E(`/optouts/reverse`)
      ._patch<PaydaysMutationResponse>()
      .seal(),
}

export const enrollmentIssues = {
  all: () =>
    E(`/enrollment-issues/`)
      ._get<PaydaysEnrollmentIssue[]>()
      .seal(),
  byId: (id?: string) =>
    E(`/enrollment-issues/${id}`)._post().seal(),
}

export const cron = {
  all: () =>
    E("/crontab/status")._get<PaydaysCron[]>().seal(),
  reset: (id?: string) =>
    E(`/crontab/reset/${id}`)._post().seal(),
  run: (id?: string) =>
    E(`/crontab/run/${id}`)._post().seal(),
}

export const charges = {
  byCompanyId: (company_id: string) =>
    E(`/reports/charges/${company_id}`)
      ._get<PaydaysOutstandingCharge[]>()
      .seal(),
}

export const campaigns = {
  all: () =>
    E("/emailcampaigns/")
      ._get<PaydaysCampaign[]>()
      ._post()
      .seal(),
  byId: (id?: string) =>
    E(`/emailcampaigns/${id}`)
      ._get<PaydaysCampaign>()
      ._patch()
      .seal(),
  linkedTrial: (id?: string) =>
    E(`/emailcampaigns/${id}/linked_trial`)
      ._get<PaydaysLinkedTrial>()
      ._delete()
      ._patch()
      .seal(),
  segments: {
    all: () =>
      E(`/segments/`)
        ._get<PaydaysSegment[]>()
        ._patch()
        ._post()
        .seal(),
    byId: (id?: string) =>
      E(`/segments/${id}`)._patch().seal(),
    dependents: (segmentId?: string) =>
      E(`/segments/${segmentId}/dependents`)
        ._get<PaydaysSegmentDependents>()
        .seal(),
    test: () =>
      E(`/segments/test`)
        ._post<PaydaysSegmentTestData>()
        .seal(),
  },
  test: (id?: string) =>
    E(`/emailcampaigns/${id}/test`)._post().seal(),
  trials: {
    all: () => E("/trials/")._get<PaydaysTrial[]>().seal(),
    treatments: (id?: string) =>
      E(`/trials/${id}/treatments`)
        ._get<PaydaysTreatment[]>()
        .seal(),
  },
}

export const discounts = {
  all: () =>
    E("/discounts/admin/retailer/all")
      ._get<PaydaysDiscountRetailers[]>()
      .seal(),
  categories: () =>
    E("/discounts/admin/categories")
      ._get<PaydaysDiscountCategoryHome[]>()
      ._post<PaydaysDiscountCategoryCreatePayload>()
      .seal(),
  category: (id?: string) =>
    E(`/discounts/admin/categories/${id}`)
      ._get<PaydaysDiscountCategory>()
      ._patch()
      .seal(),
  categoryRanking: (discount_category_id?: string) =>
    E(`/discounts/admin/categories/rankings`)
      ._patch()
      .seal(),
  config: () =>
    E("/discounts/config")
      ._get<PaydaysDiscountsConfig>()
      .seal(),
  createDiscount: () =>
    E(`/discounts/admin/discount`)
      ._post<{ discount_id: string }>()
      .seal(),
  createRetailer: (createCashbackOffer: boolean) =>
    E(
      `/discounts/admin/retailer?create_cashback_offer=${
        createCashbackOffer ? "true" : "false"
      }`
    )
      ._post<{ discount_retailer_id: string }>()
      .seal(),
  discount: (id?: string) =>
    E(`/discounts/admin/discount/${id}`)
      ._get<PaydaysDiscount>()
      ._patch()
      .seal(),
  discountsForRetailer: ({ id }: { id?: string }) =>
    E(`/discounts/admin/retailer/${id}`)
      ._get<PaydaysDiscountForRetailer[]>()
      .seal(),
  homepageProductFeed: () =>
    E("/discounts/admin/product_feed")
      ._get<PaydaysDiscountsHomepageProductFeed[]>()
      ._patch()
      .seal(),
  promoted: () =>
    E("/discounts/admin/promoted")
      ._get<PaydaysDiscountsPromotedGet[]>()
      ._patch()
      .seal(),
  retailerConfig: (id?: string) =>
    E(`/discounts/admin/retailer/${id}/config`)
      ._get<PaydaysDiscountRetailers>()
      ._patch()
      .seal(),
  uploadImage: () =>
    E(`/discounts/admin/upload_image`)
      ._post<{ image_url: string }>()
      .seal(),
  uploadSingleUseCodes: (discount_id: string) =>
    E(
      `/discounts/admin/discount/${discount_id}/upload_single_use_codes`
    )
      ._post()
      .seal(),
}

export const emailTemplates = {
  all: () =>
    E("/emailtemplates/", { show_all: true })
      ._get<PaydaysEmailTemplate[]>()
      ._post()
      .seal(),
  byId: (id?: string) =>
    E(`/emailtemplates/${id}`)
      ._get<PaydaysEmailTemplate>()
      ._post()
      ._patch()
      ._delete()
      .seal(),
  content: (id?: string, companyId?: string) =>
    E(`/emailtemplates/${id}/content/${companyId}`)
      ._get<PaydaysEmailTemplateContent>()
      .seal(),
  render: (id?: string, companyId?: string) =>
    E(`/emailtemplates/${id}/render/${companyId}`)
      ._get<void, PaydaysEmailTemplateRender>()
      .seal(),
}

export const inAppDialogs = {
  all: (withSegments?: boolean) =>
    E("/inappdialogs/", { segments: withSegments })
      ._get<void, { dialogs: PaydaysDialog[] }>()
      .seal(),
  byId: (id?: string) =>
    E(`/inappdialogs/${id}`)
      ._get<void, { dialog: PaydaysDialog }>()
      ._post()
      ._patch()
      .seal(),
}

export const paySchedules = {
  all: () =>
    E("/payschedules/")
      ._get<PaydaysPaySchedule[]>()
      ._post<PaydaysAddPayScheduleResponse>()
      .seal(),
  archive: (payScheduleId: string) =>
    E(`/payschedules/${payScheduleId}/archive`)
      ._patch()
      .seal(),
  bulkInsert: (payScheduleId?: string) =>
    E(`/payschedules/${payScheduleId}/bulkinsert/dates`)
      ._post<
        PaydaysMutationResponse<{
          err_message?: string
          message: unknown
        }>
      >()
      .seal(),
  byCompanyId: (id?: string) =>
    E(`/payschedules/${id}`, {
      past: true,
    })
      ._get<PaydaysCompanyPaySchedule[]>()
      .seal(),
  byId: (payScheduleId?: string) =>
    E(`/payschedules/${payScheduleId}`)._patch().seal(),
  clone: (payScheduleId: string) =>
    E(`/payschedules/${payScheduleId}/clone`)
      ._post<PaydaysClonePayScheduleResponse>()
      .seal(),
  dates: {
    approve: (
      payScheduleId: string,
      payScheduleDateId: string
    ) =>
      E(
        `/payschedules/${payScheduleId}/dates/${payScheduleDateId}/approve`
      )
        ._post<PaydaysPayScheduleDate[]>()
        .seal(),
    bulkDelete: (payScheduleId: string) =>
      E(`/payschedules/${payScheduleId}/bulk-delete-dates`)
        ._patch()
        .seal(),
    byDateId: (
      payScheduleId: string,
      payScheduleDateId: string
    ) =>
      E(
        `/payschedules/${payScheduleId}/dates/${payScheduleDateId}`
      )
        ._delete()
        ._put()
        .seal(),
    byPayScheduleId: (
      payScheduleId?: string,
      withSuggestions?: boolean
    ) =>
      E(`/payschedules/${payScheduleId}/dates`, {
        suggestions: withSuggestions,
      })
        ._get<PaydaysPayScheduleDate[]>()
        ._post()
        .seal(),
    generate: (payScheduleId: string) =>
      E(`/payschedules/${payScheduleId}/generate-dates`)
        ._post()
        .seal(),
    generateDemo: (payScheduleId: string) =>
      E(
        `/payschedules/${payScheduleId}/generate-demo-dates`
      )
        ._post()
        .seal(),
  },
  generateEnhancements: (payScheduleId: string) =>
    E(`/payschedules/${payScheduleId}/generate-enhancement`)
      ._post<PaydaysNewPaySchedule>()
      .seal(),
  lock: (payScheduleId: string) =>
    E(`/payschedules/${payScheduleId}/lock`)
      ._patch()
      .seal(),
  next: () =>
    E(`/payschedules/next`)
      ._get<PaydaysNextPaySchedule[]>()
      .seal(),
  properties: {
    byId: (payScheduleId?: string) =>
      E(`/payschedules/${payScheduleId}/properties`)
        ._options<Schema>()
        ._put()
        .seal(),
    changelog: (payScheduleId?: string) =>
      E(
        `/payschedules/${payScheduleId}/properties/changelog`
      )
        ._get<PaydaysPropertyChangelog[]>()
        .seal(),
  },
  restore: (payScheduleId: string) =>
    E(`/payschedules/${payScheduleId}/restore`)
      ._patch()
      .seal(),
  single: (payScheduleId?: string) =>
    E(`/payschedules/${payScheduleId}/single`)
      ._get<PaydaysPaySchedule>()
      .seal(),
  unlock: (payScheduleId: string) =>
    E(`/payschedules/${payScheduleId}/unlock`)
      ._patch()
      .seal(),
  usedByCompanyId: (companyId?: string) =>
    E(`/payschedules/company/${companyId}/used`)
      ._get<PaydaysPayScheduleUsedByCompany[]>()
      .seal(),
}

export const achDebit = {
  exclusionList: {
    all: () =>
      E("/plaid/salary_deposit/exclusion_phrases")
        ._get<ACHDebitExclusionPhrase[]>()
        ._post<ACHDebitExclusionPhrase>()
        .seal(),
    byId: (id?: string) =>
      E(`/plaid/salary_deposit/exclusion_phrases/${id}`)
        ._delete()
        .seal(),
  },
  inclusionList: {
    all: () =>
      E("/plaid/salary_deposit/inclusion_phrases")
        ._get<ACHDebitInclusionPhrase[]>()
        ._post<ACHDebitInclusionPhrase>()
        .seal(),
    byId: (id?: string) =>
      E(`/plaid/salary_deposit/inclusion_phrases/${id}`)
        ._delete()
        .seal(),
  },
}

export const sme = {
  compliance: {
    byId: (id?: string) =>
      E(`/compliance/company/gb/${id}`)
        ._get<PaydaysSmeComplianceStatus>()
        .seal(),
    byUserId: (userId?: string) =>
      E(`/compliance/user/gb/${userId}`)._get().seal(),
    relevantPeople: (id?: string) =>
      E(`/compliance/company/gb/${id}/relevant_people`)
        ._get<{
          relevant_people: PaydaysSmeRelevantPeople[]
        }>()
        .seal(),
  },
  onboarding: (id?: string) =>
    E(`/sme/onboarding/${id}`)
      ._get<PaydaysOnboardingWorkflow>()
      .seal(),
  subscription: (id?: string) =>
    E(`/stripe/subscription/${id}`)
      ._get<{ subscriptions: PaydaysSmeSubscription[] }>()
      .seal(),
  task: (id?: string, taskCode?: string) =>
    E(`/sme/onboarding/${id}/${taskCode}`)._patch().seal(),
}

export const devices = {
  byId: (id?: string) =>
    E(`/devices/${id}`)
      ._get<PaydaysDevice>()
      ._patch()
      ._delete()
      .seal(),
  experiments: (deviceId?: string) =>
    E(`/devices/${deviceId}/trials`)
      ._get<PaydaysDeviceExperiment[]>()
      .seal(),
}

export const users = {
  admins: () =>
    E(`/users/admins`)._get<PaydaysAdminUser[]>().seal(),
  agreements: (userId?: string) =>
    E(`/agreements/user/${userId}`)
      ._get<PaydaysUserAgreement[]>()
      .seal(),
  byId: (id?: string) =>
    E(`/users/${id}`)
      ._get<
        void,
        { user: PaydaysUser; devices: PaydaysUserDevice[] }
      >()
      ._patch()
      .seal(),
  cards: (userId?: string) =>
    E(`/cards/${userId}?showall=true`)
      ._get<PaydaysCard[]>()
      .seal(),
  clear_card_errors: (cardId?: string) =>
    E(`/cards/${cardId}/clear`)
      ._post<PaydaysCardClearResponse[]>()
      .seal(),
  employees: (id?: string) =>
    E(`/users/${id}/employees`)
      ._get<PaydaysEmployment[]>()
      .seal(),
  experiments: (userId?: string) =>
    E(`/users/${userId}/trials`)
      ._get<PaydaysUserExperiment[]>()
      .seal(),
  history: (id?: string) =>
    E(`/users/${id}/history`)
      ._get<PaydaysUserHistory[]>()
      .seal(),
  logins: (userId?: string) =>
    E(`/users/${userId}/logins`)
      ._get<PaydaysUserLogin[]>()
      .seal(),
  password: {
    byId: (id?: string) =>
      E(`/users/${id}/password`)._post().seal(),
  },
  rootUsers: () =>
    E(`/users/root-users`)._get<PaydaysRootUser[]>().seal(),
  suspension: (userId?: string) =>
    E(`/users/${userId}/suspension`)._delete().seal(),
}

export const tags = {
  entity: (entity: TagEntities, id?: string) =>
    E(`/tags/${entity}/${id}`)
      ._post<EntityTagPayload>()
      ._get<UserTagsResponse>()
      .seal(),
  entity_tag: (
    entity: TagEntities,
    entity_id?: string,
    tag?: string
  ) =>
    E(`/tags/${entity}/${entity_id}/tag/${tag}`)
      ._put<EntityTagPayload>()
      ._delete<{ reason: string }>()
      .seal(),
  list: (entity: TagEntities) =>
    E(`/tags/${entity}`)
      ._post<Tag>()
      ._get<TagListResponse>()
      .seal(),
  tag: (entity: TagEntities, tag: string) =>
    E(`/tags/${entity}/tag/${tag}`)
      ._put<Tag>()
      ._delete()
      .seal(),
}

export const transactions = {
  recent: (
    employeeId: string,
    startsOn: string,
    endsOn: string
  ) =>
    E(`/transactions/`, {
      employee_id: employeeId,
      ends_on: endsOn,
      starts_on: startsOn,
    })
      ._get<PaydaysTransaction[]>()
      .seal(),
  remove: (id: string) =>
    E(`/transactions/${id}`)._delete().seal(),
  removed: (employeeId: string, startsOn: string) =>
    E(`/transactions/removed`, {
      employee_id: employeeId,
      starts_on: startsOn,
    })
      ._get<PaydaysTransaction[]>()
      .seal(),
  retry: (transactionId: string) =>
    E(`/transactions/${transactionId}/retry`)
      ._post<{
        data: boolean | null | Record<string, unknown>
      }>()
      .seal(),
  unsettled: (employeeId: string, startsOn: string) =>
    E(`/transactions/unsettled`, {
      employee_id: employeeId,
      starts_on: startsOn,
    })
      ._get<PaydaysUnsettledTransaction[]>()
      .seal(),
}

export const documentation = {
  companyIntegrationProperties: () =>
    E("/documentation/company_integration_properties")
      ._get<GenericJSONSchemaProperty[]>()
      .seal(),
  companyProperties: () =>
    E("/documentation/company_properties")
      ._get<GenericJSONSchemaProperty[]>()
      .seal(),
  payScheduleProperties: () =>
    E("/documentation/pay_schedules_properties")
      ._get<GenericJSONSchemaProperty[]>()
      .seal(),
}

export const companies = {
  WipeCompany: (id?: string) =>
    E(`/companies/companywipe/${id}`)._delete().seal(),
  activate: (id?: string) =>
    E(`/companies/${id}/activate`)._post().seal(),
  activateHUBUC: (id?: string) =>
    E(`/companies/${id}/activate/hubuc`)._post().seal(),
  activateLHV: (id?: string) =>
    E(`/companies/${id}/activate/lhv`)._post().seal(),
  activateLloyds: (id: string) =>
    E(`/companies/${id}/activate/lloyds`)._post().seal(),
  activateModulr: (id?: string) =>
    E(`/companies/${id}/activate/modulr`)._post().seal(),
  admins: {
    byId: (id?: string) =>
      E(`/companyadmins/${id}`)
        ._get<PaydaysCompanyAdmin[]>()
        ._post<PaydaysCompanyAdminResponse>()
        ._delete()
        .seal(),
  },
  all: () =>
    E("/reports/companies")._get<PaydaysCompany[]>().seal(),
  balanceRecalculation: (id?: string) =>
    E(`/companies/${id}/recalculate-employee-balances`)
      ._post()
      .seal(),
  bankingProviderProperties: (
    companyId: string,
    bankingProvider: string
  ) =>
    E(`/companies/${companyId}/banking/${bankingProvider}`)
      ._get<Record<string, unknown>>()
      ._post<unknown>()
      ._options<Schema>()
      .seal(),
  byId: (id?: string) =>
    E(`/companies/${id}`)
      ._get<PaydaysCompany>()
      ._post()
      ._patch()
      .seal(),
  comments: {
    byId: (id?: string) =>
      E(`/comments/companies/${id}`)
        ._get<PaydaysCompanyComment[]>()
        ._post<PaydaysAddCompanyComment>()
        .seal(),
  },
  companySearch: (q: string) =>
    E(`/sme/company/gb/search?q=${q}`)
      ._get<PaydaysCompanySearch>()
      .seal(),
  config: (id?: string) =>
    E(`/companies/${id}/config`)
      ._get<PaydaysCompanyConfiguration>()
      .seal(),
  create: () => E(`/companies/`)._post().seal(),
  credit_limit: (id?: string) =>
    E(`/companies/${id}/credit_limit`)
      ._get<PaydaysCompanyCreditLimit>()
      .seal(),
  dataSets: {
    byId: (companyId?: string) =>
      E(`/companies/${companyId}/extended-datasets`)
        ._get<void, PaydaysCompanyDataSet[]>()
        ._post()
        .seal(),
  },
  divisions: {
    all: () =>
      E(`/divisions/`)
        ._get<PaydaysDivision[]>()
        ._post<PaydaysAddCompanyDivision>()
        .seal(),
    byId: (companyId?: string) =>
      E(`/reports/companies/${companyId}/divisionrank`)
        ._get<PaydaysCompanyDivision[]>()
        .seal(),
    divisionManagers: (companyId?: string) =>
      E(`companies/${companyId}/managers`)
        ._get<PaydaysCompanyDivisionManager[]>()
        .seal(),
    newDivisionManager: (divisionId?: string) =>
      E(`/divisions/${divisionId}/managers`)._post().seal(),
    removeDivisionManager: (divisionId?: string) =>
      E(`/divisions/${divisionId}/manager`)
        ._delete()
        .seal(),
  },
  emailTemplates: (id?: string) =>
    E("/emailtemplates/", {
      company_id: id,
    })
      ._get<PaydaysEmailTemplate[]>()
      .seal(),
  employeeSettings: {
    update: () =>
      E(`/companies/employeesettings/`)
        ._post<PaydaysUpdateDefaultTransferLimits>()
        .seal(),
  },
  employeeSnapshot: (id?: string) =>
    E(`/companies/${id}/employeesnapshot`)
      ._get<PaydaysCompanyEmployeeSnapshot[]>()
      .seal(),
  files: {
    all: (companyId?: string) =>
      E(`/companies/${companyId}/client-uploads`)
        ._get<PaydaysCompanyFile[]>()
        .seal(),
    byId: (companyId?: string, id?: string) =>
      E(
        `/companies/${companyId}/client-uploads/${id}`
      ).seal(),
  },
  fourthEmployeeLookup: {
    byId: (id?: string) =>
      E(`/fourth/verify_user/${id}`)
        ._post<{
          matches: PaydaysCompanyFourthEmployee[]
        }>()
        .seal(),
  },
  fourthSync: () =>
    E(`/fourth/batch_banking`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  fundingEntity: {
    get: (id?: string) =>
      E(`/companies/funding-entity`, {
        company_id: id,
      })
        ._get<PaydaysCompanyFundingEntity>()
        .seal(),
    post: () =>
      E(`/companies/funding-entity`)._post().seal(),
  },
  generatePayloadForLloydsAccountCreation: (id: string) =>
    E(`/lloyds/${id}/accounts`)._post().seal(),
  hypercareChecks: (id?: string) =>
    E(`/hypercare/checks/${id}`)
      ._get<PaydaysHypercareCheck[]>()
      ._post<{
        data: { results: PaydaysHypercareCheck[] }
      }>()
      .seal(),
  integrations: {
    allocateShifts: (
      integrationId?: string,
      assignmentNumber?: string,
      shiftDate?: string,
      includeESRData = false
    ) =>
      E(
        `/companies/integrations/allocate/${integrationId}/shifts`,
        {
          assignment_number: assignmentNumber,
          include_esr_data: includeESRData,
          shift_date: shiftDate,
        }
      )
        ._get<PaydaysAllocateIntegrationShiftData>()
        .seal(),
    byCompanyId: (id?: string, showAll = false) =>
      E(`/companies/integrations/${id}`, {
        show_all: showAll,
      })
        ._get<PaydaysCompanyIntegration[]>()
        .seal(),
    byCompanyIdDecrypted: (id?: string, showAll = false) =>
      E(`/companies/integrations/${id}/decrypted`, {
        show_all: showAll,
      })
        ._get<PaydaysCompanyIntegration[]>()
        .seal(),
    byId: (id?: string) =>
      E(`/companies/integrations/${id}`)
        ._post()
        ._patch()
        ._delete()
        .seal(),
    deleteById: (id?: string) =>
      E(`/companies/integrations/${id}/delete`)
        ._delete()
        .seal(),
    esrLookup: (
      assignmentNumber?: string,
      surname?: string,
      dob?: string
    ) =>
      E("/companies/integrations/esr/test/employee", {
        assignment_number: assignmentNumber,
        dob,
        surname,
      })
        ._get<PaydaysESRLookup>()
        .seal(),
    file: {
      byId: (companyId?: string, id?: string) =>
        E(`/companies/integrations/${companyId}/file/${id}`)
          ._get()
          .seal(),
      reupload: (companyId?: string, id?: string) =>
        E(
          `/companies/integrations/${companyId}/file/${id}/reupload`
        )
          ._post()
          .seal(),
    },
    fourthRunpost: (tablePrefix?: string) =>
      E(
        `/companies/integrations/fourth/runpost/${tablePrefix}`
      )
        ._post()
        .seal(),
    innoviseBranchesById: (id?: string) =>
      E(`/companies/integrations/innovise/${id}/branches`)
        ._get<PaydaysInnoviseIntegrationBranchEntry[]>()
        .seal(),
    latestBucket: {
      byFileKey: (companyId?: string) =>
        E(
          `/companies/integrations/${companyId}/download-bucket-files`
        )
          ._get()
          .seal(),
      byId: (companyId?: string) =>
        E(
          `/companies/integrations/${companyId}/latest-bucket-files`
        )
          ._get<PaydaysIntegrationsLatestBucketFile[]>()
          .seal(),
    },
    linkByIntegrationId: (integrationId?: string) =>
      E(
        `/companies/integrations/${integrationId}/link-transform`
      )
        ._patch()
        .seal(),
    partners: () =>
      E("companies/integrations/partners")
        ._get<string[]>()
        .seal(),
    properties: {
      byId: (id: string) =>
        E(`companies/integrations/${id}/properties/editor`)
          ._put()
          ._options<Schema>()
          .seal(),
      changelog: (id?: string) =>
        E(
          `/companies/integrations/${id}/properties/editor/changelog`
        )
          ._get<PaydaysPropertyChangelog[]>()
          .seal(),
    },
    results: {
      all: (id?: string) =>
        E(`/companies/integrations/${id}/results`, {
          show_all: true,
        })
          ._get<PaydaysIntegrationSyncResults[]>()
          .seal(),
      byId: (integrationId?: string, syncId?: string) =>
        E(
          `/companies/integrations/${integrationId}/result/${syncId}`
        )
          ._get<PaydaysIntegrationSyncResult[]>()
          ._delete()
          .seal(),
    },
    sftpReport: {
      byId: (companyId?: string) =>
        E(
          `/companies/integrations/${companyId}/sftp-report`
        )
          ._get()
          .seal(),
      info: (companyId?: string) =>
        E(
          `/companies/integrations/${companyId}/sftp-report-info`
        )
          ._get<PaydaysIntegrationSFTPReportInfo>()
          .seal(),
    },
    tables: (id?: string) =>
      E(`/companies/integrations/${id}/tables`)
        ._get<PaydaysFourthTable[]>()
        .seal(),
  },
  payrollReturnPaymentBankingDetails: {
    byId: (id?: string) =>
      E(`/companies/${id}/banking`)
        ._post<PaydaysAddPayrollReturnBankDetails>()
        .seal(),
  },
  portalUserRoles: {
    byCompanyId: (companyId: string, roleIds?: string) =>
      E(
        `/talent_pool/portal_users/${companyId}?role_ids=${roleIds}`
      )
        ._get<RaisePortalUser[]>()
        ._post<PaydaysCompanyAdminResponse>()
        .seal(),
    byUserId: (
      companyId: string,
      userId: string | null,
      roleId: string | null,
      entityId: string | null
    ) =>
      E(
        `/talent_pool/portal_users/${companyId}/${userId}?role_id=${roleId}&entity_id=${entityId}`
      )
        ._delete()
        .seal(),
  },
  properties: {
    byId: (id?: string, includeHidden?: boolean) =>
      E(
        `/companies/${id}/properties`,
        includeHidden ? { include_hidden: true } : undefined
      )
        ._post<unknown>()
        ._patch()
        ._delete()
        ._put()
        ._options<Schema>()
        .seal(),
    changelog: (id?: string) =>
      E(`/companies/${id}/properties/changelog`)
        ._get<PaydaysPropertyChangelog[]>()
        .seal(),
  },
  propertiesConfig: (id?: string) =>
    E(`/companies/${id}/companypropertiesconfig`)
      ._get<PaydaysCompanyProperties>()
      ._post<PaydaysCompanyProperties>()
      .seal(),
  salary: {
    activity: (id?: string) =>
      E(`/reports/companies/${id}/salary`)
        ._get<PaydaysCompanySalaryActivity>()
        .seal(),
    pdf: (
      id?: string,
      starts?: string | null,
      ends?: string | null
    ) =>
      E(`/reports/companies/${id}/salary/pdf`, {
        ends_on: ends,
        starts_on: starts,
      }).seal(),
  },
  subscriptions: {
    add: () =>
      E(`/billing/subscriptions`)
        ._post<PaydaysSubscriptions>()
        .seal(),
    byId: (id?: string) =>
      E(`/billing/subscriptions/${id}`)
        ._get<PaydaysSubscriptions>()
        .seal(),
  },
}

export const webUsers = {
  byCompanyId: (id: string) =>
    E(`/webusers/company/${id}`)
      ._get<void, { content: PaydaysWebUser[] }>()
      ._post()
      .seal(),
  byId: (id: string) =>
    E(`/webusers/${id}`)._delete().seal(),
  createDemoUsersForCompany: (id: string) =>
    E(`/webusers/create-demo-users/${id}`)
      ._post<{
        managers_created?: string[]
        managers_failed?: string[]
      }>()
      .seal(),
}

export const salaries = {
  add_salary: (amount?: number) =>
    E(`/salaries/salary`)._post().seal(),
  disabled: (employeeId?: string) =>
    E(`/salaries/disabled`, {
      employee_id: employeeId,
    })
      ._get<PaydaysSalaryDisabled[]>()
      .seal(),
  ignore_salary: (salaryId?: string) =>
    E(`/salaries/${salaryId}/ignore_salary`)._post().seal(),
  payday: (payday?: string) =>
    E("/salaries/payday", {
      payday: payday,
    })
      ._get<void, PaydaysSalaryPayday>()
      .seal(),
  pdf: (salaryId?: string) =>
    E(`/reports/salaries/${salaryId}/pdf`).seal(),
  reenable: (salaryId?: string) =>
    E(`/salaries/${salaryId}/reenable`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  report: (salaryId?: string) =>
    E(`/reports/salaries/${salaryId}`)
      ._get<PaydaysSalary>()
      .seal(),
  return: (salaryId?: string) =>
    E(`/salaries/${salaryId}/return`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  returnDisabled: (salaryId?: string) =>
    E(`/salaries/${salaryId}/return-disabled`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  reverse: (salaryId?: string) =>
    E(`/salaries/${salaryId}/reverse`)._post().seal(),
  summary: (employeeId?: string, startsOn?: string) =>
    E("/salaries/summary", {
      employee_id: employeeId,
      starts_on: startsOn,
    })
      ._get<PaydaysSalary[]>()
      .seal(),
  transactions: (salaryId?: string) =>
    E(`/salaries/${salaryId}/transactions`)
      ._get<PaydaysTransaction[]>()
      .seal(),
}

export const shifts = {
  byEmployeeId: (employeeId?: string, removed = false) =>
    E(
      `/shifts/employee/${employeeId}`,
      removed
        ? {
            removed,
          }
        : undefined
    )
      ._get<PaydaysShift[]>()
      .seal(),
  byId: (shiftId?: string) =>
    E(`/shifts/${shiftId}`)._delete<unknown>().seal(),
}

export const badAccruals = {
  byEmployeeId: (employeeId: string, sinceDate: string) =>
    E(`/sme/${employeeId}/shifts?since_date=${sinceDate}`)
      ._delete<unknown>()
      .seal(),
}

export const weeklyPaySummary = {
  byEmployeeId: (employeeId?: string) =>
    E(
      `/sme/weekly_pay/summary/employee/${employeeId}?next_payday=True`
    )
      ._get<PayDaysGetWeeklyPaySummary>()
      .seal(),
}

export const rotas = {
  byEmployeeId: (employeeId?: string) =>
    E(`/rota/employee/${employeeId}`)
      ._get<PaydaysRota[]>()
      .seal(),
}

export const contacts = {
  all: () =>
    E("/companycontacts/")
      ._get<void, PaydaysCompanyContact[]>()
      ._post()
      .seal(),
  byCompany: (id?: string) =>
    E(`/companycontacts/companies/${id}`)
      ._get<void, PaydaysCompanyContact[]>()
      ._post()
      .seal(),
  byId: (id?: string) =>
    E(`/companycontacts/${id}`)._delete().seal(),
  type: (id?: string, type?: string) =>
    E(`/companycontacts/${id}/${type}`)
      ._delete()
      ._put()
      .seal(),
}

export const employees = {
  anonymise: (employeeId?: string) =>
    E(`/employees/${employeeId}/anonymise`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  approveKeeper: () =>
    E(`/employees/ws-keepers/approve`)
      ._post<{ ok: boolean }>()
      .seal(),
  balances: {
    current: {
      byId: (
        employeeId?: string,
        employeeContractId?: string
      ) =>
        E(`/balances/current/${employeeId}`, {
          employee_contract_id: employeeContractId,
        })
          ._get<PaydaysEmployeeBalance>()
          .seal(),
    },
    getHistory: {
      byId: (employeeId: string) =>
        E(`balances/gethistory/${employeeId}`, {
          limit: 500,
        })
          ._get<PaydaysEmployeeBalanceHistory[]>()
          .seal(),
    },
    history: {
      byId: (employeeId?: string) =>
        E(`balances/history/${employeeId}`)
          ._get<PaydaysHistoryTransaction[]>()
          .seal(),
    },
  },
  banking: (id?: string) =>
    E(`/employees/${id}/banking`)._patch().seal(),
  byCompany: (
    id?: string,
    limit?: string,
    offset?: string
  ) =>
    E("/reports/employees", {
      company_id: id,
      limit,
      offset,
    })
      ._get<PaydaysEmployee[]>()
      .seal(),
  byId: (id?: string | null) =>
    E(`/employees/${id}`)
      ._get<PaydaysEmployee>()
      ._patch<PaydaysEmployeePatch>()
      .seal(),
  deactivateKeeper: (id?: string) =>
    E(`/employees/${id}/deactivate`)
      ._post<{ ok: boolean }>()
      .seal(),
  dpeOverages: (employeeId?: string) =>
    E(`/dpe/dpe_overages/${employeeId}`)
      ._get<PaydaysDPEOverage[]>()
      .seal(),
  dpePaymentElections: (employeeId?: string) =>
    E(`/paymentelections/dpe/${employeeId}`)
      ._get<PaydaysEmployeePaymentElectionResonse>()
      .seal(),
  emails: (id?: string) =>
    E(`/comm/sent/${id}`)
      ._get<PaydaysEmployeeEmail[]>()
      .seal(),
  esrLookup: (id?: string) =>
    E(`/employees/${id}/esr-api-lookup`)
      ._get<void, PaydaysESRLookup>()
      .seal(),
  forceSalaryDetection: () =>
    E(`plaid/salary_deposit_mappings`)._post().seal(),
  history: {
    byId: (id?: string) =>
      E(`/employees/${id}/history`)
        ._get<void, PaydaysEmployeeHistory>()
        .seal(),
  },
  invite: () =>
    E(`/employees/invite`)._post<unknown>().seal(),
  microdeposits: {
    all: (id?: string) =>
      E(`/employees/${id}/microdeposit/all`)
        ._get<void, PaydaysEmployeeMicrodeposits>()
        .seal(),
  },
  optOut: (employeeId?: string) =>
    E(`useroptouts/employee/${employeeId}/admin/optout`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  optOutReverse: (employeeId?: string) =>
    E(`useroptouts/employee/${employeeId}/reverse`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  plaid: {
    recentTransactions: (
      employeeId?: string,
      fromDate?: string
    ) =>
      E(
        `/plaid/recent_transactions/${employeeId}?from_date=${fromDate}&page_size=1000`
      )
        ._get<PaydaysPlaidTransaction[]>()
        .seal(),
  },
  relink: (employeeId?: string) =>
    E(`/employees/${employeeId}/relink-elections`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  rerunSalaryDetection: (employeeId?: string) =>
    E(`plaid/salary_deposit_candidates/${employeeId}`)
      ._post()
      .seal(),
  resendInvite: (employeeId?: string, companyId?: string) =>
    E(
      `/employees/${employeeId}/invite?company_id=${companyId}`
    )
      ._post<{ employee_invited: boolean }>()
      .seal(),
  reset: (employeeId?: string) =>
    E(`employees/${employeeId}/reset`)
      ._patch<PaydaysResetResponse>()
      .seal(),
  salaryDetection: (employeeId?: string) =>
    E(
      `plaid/salary-deposit-candidate-history/${employeeId}`
    )
      ._get<PaydaysSalaryDetection>()
      .seal(),
  sendIVRInvite: (employeeId?: string) =>
    E(`/employees/${employeeId}/send_ivr_invitation`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  states: () => E(`/employees/states`)._patch().seal(),
  stuck: (employeeId?: string) =>
    E(`/employees/${employeeId}/stuck`)
      ._post<PaydaysMutationResponse>()
      .seal(),
  syncs: (employeeId?: string) =>
    E(`/reports/sync-history`, { employee_id: employeeId })
      ._get<PaydaysEmployeeSyncResult[]>()
      .seal(),
  toogleKeepersLogoutFeature: (id?: string) =>
    E(`/employees/ws-keepers/${id}/toggle-logout-feature`)
      ._post<{ ok: boolean }>()
      .seal(),
  unlock: (employeeId?: string) =>
    E(`/employees/${employeeId}/unlock`)
      ._patch<PaydaysMutationResponse>()
      .seal(),
  unlockControls: (employeeId?: string) =>
    E(`/employees/${employeeId}/unlock-controls`)
      ._post<PaydaysMutationResponse>()
      .seal(),
}

export const search = {
  companyById: (id: string) =>
    E(`/search/company_id/${id}`)
      ._get<PaydaysSearchResponse, PaydaysSearchResponse>()
      .seal(),
  companyByName: (searchValue: string) =>
    E(`/search/company_name/${searchValue}`)
      ._get<PaydaysSearchResponse, PaydaysSearchResponse>()
      .seal(),
  employeeByCode: (searchValue: string) =>
    E(`/search/employee_code/${searchValue}`)
      ._get<PaydaysSearchResponse, PaydaysSearchResponse>()
      .seal(),
  employeeById: (id: string) =>
    E(`/search/employee_id/${id}`)
      ._get<PaydaysSearchResponse, PaydaysSearchResponse>()
      .seal(),
  employeeByName: (searchValue: string) =>
    E(`/search/employee_full_name/${searchValue}`)
      ._get<PaydaysSearchResponse, PaydaysSearchResponse>()
      .seal(),
  employeeByWorkEmail: (searchValue: string) =>
    E(`/search/employee_work_email/${searchValue}`)
      ._get<PaydaysSearchResponse, PaydaysSearchResponse>()
      .seal(),
  userByEmail: (searchValue: string) =>
    E(`/search/user_email/${searchValue}`)
      ._get<PaydaysSearchResponse, PaydaysSearchResponse>()
      .seal(),
  userById: (id: string) =>
    E(`/search/user_id/${id}`)
      ._get<PaydaysSearchResponse, PaydaysSearchResponse>()
      .seal(),
}

export const nhsRPARequests = () =>
  E(`/nhs_rpa_requests/`)
    ._patch<{
      data: { companyIds: string[] }
    }>()
    ._post<PaydaysRPAData>()
    .seal()

export const getNhsRPARequests = (id?: string) =>
  E(`/nhs_rpa_requests/${id}`)
    ._get<PaydaysGetRPARequestData[]>()
    .seal()

export const nhsRPATestRequests = () =>
  E(`/nhs_rpa_test_requests/`)
    ._patch<{
      data: { companyIds: string[] }
    }>()
    ._post<PaydaysTestRPAData>()
    .seal()

export const zellisZipRequests = {
  pay_elements: (companyIntegrationId: string) =>
    E(
      `/zellis_zip/set-up/${companyIntegrationId}/pay-elements`
    )
      ._get<ZellisZIPPayElementResponse>()
      .seal(),
  sort_codes: (companyIntegrationId: string) =>
    E(
      `/zellis_zip/set-up/${companyIntegrationId}/sort-codes`
    )
      ._get<ZellisZIPSortCodeResponse>()
      .seal(),
  test_credentials: () =>
    E(`/zellis_zip/test-credentials`)
      ._post<ZellisZIPCredentialsTestResponse>()
      .seal(),
}

export const banking = {
  balances: (provider?: string, atTimestamp?: string) =>
    E(`/banking/ledgers/major/balance`, {
      at_timestamp: atTimestamp,
      banking_providers: provider,
    })
      ._get()
      .seal(),
  beneficiary: (provider?: string, ledgerId?: string) =>
    E(`/${provider}/beneficiaries/${ledgerId}`)
      ._get<PaydaysBankingBeneficiary>()
      .seal(),
  details: (
    provider?: string,
    entity?: string,
    id?: string
  ) => E(`/${provider}/${entity}/${id}`)._get().seal(),
  manualTransaction: () =>
    E(`/banking/transactions`)
      ._post<ManualTransaction>()
      .seal(),
  sortcode: (code?: string) =>
    E(`/sortcodes/?code=${code}`)
      ._get<PaydaysSortcode[]>()
      .seal(),
}

export const corporateGroup = {
  all: () =>
    E("/corporate_groups/")
      ._get<Omit<PaydaysCorporateGroup, "companies">[]>()
      ._post()
      .seal(),
  byId: (id?: string) =>
    E(`corporate_groups/${id}`)
      ._get<PaydaysCorporateGroup>()
      .seal(),
}

export const transfers = {
  all: (starts?: string | null, ends?: string | null) =>
    E(`/reports/transfers`, {
      ends_on: ends,
      starts_on: starts,
    })
      ._get<PaydaysTransfer[]>()
      .seal(),
  byEmployeeId: (
    employeeId?: string,
    startsOn?: string,
    endsOn?: string
  ) =>
    E(`/reports/transfers`, {
      employee_id: employeeId,
      ends_on: endsOn,
      starts_on: startsOn,
    })
      ._get<PaydaysTransfer[]>()
      .seal(),
  byId: (id: string, removed?: boolean) =>
    E(`/transfers/${id}`, {
      removed: removed ? true : undefined,
    })
      ._get<void, PaydaysTransferResponse>()
      .seal(),
  fixMissingSent: (transferId: string) =>
    E(`/transfers/${transferId}/fix_missing_sent`)
      ._post()
      .seal(),
  remove: (transferId: string) =>
    E(`/transfers/${transferId}`)._delete().seal(),
  removed: (
    employeeId?: string,
    startsOn?: string,
    endsOn?: string
  ) =>
    E(`/reports/transfers/removed`, {
      employee_id: employeeId,
      ends_on: endsOn,
      starts_on: startsOn,
    })
      ._get<PaydaysTransfer[]>()
      .seal(),
  retry: (transferId: string) =>
    E(`/transfers/${transferId}/retry`)._post().seal(),
  settle: () =>
    E(`/transfers/settle`)
      ._post<PaydaysSettleTransfersResponse>()
      .seal(),
  transactions: (transferId: string, removed?: boolean) =>
    E(`/transfers/${transferId}/transactions`, {
      removed: removed ? true : undefined,
    })
      ._get<PaydaysTransaction[]>()
      .seal(),
}

export const payments = {
  byEmployeeId: (employeeId: string) =>
    E(`/payments/`, { employee_id: employeeId })
      ._get<PaydaysPayment[]>()
      .seal(),
}

export const paymentSources = {
  all: () =>
    E(`/payment_sources/`)
      ._get<PaydaysPaymentSource[]>()
      .seal(),
}

export const credits = {
  all: () => E(`/credits/`)._post().seal(),
  available: {
    byEmployeeId: (employeeId: string) =>
      E(`/credits/available/${employeeId}`)
        ._get<PaydaysCredit[]>()
        .seal(),
  },
  byId: (creditId: string) =>
    E(`/credits/${creditId}`)._delete().seal(),
}

export const ledger = {
  search: (sortCode?: string, accountNumber?: string) =>
    E(`/banking/search/${sortCode}/${accountNumber}`)
      ._get<PaydaysLedger[]>()
      .seal(),
}

export const configuration = {
  employeeLookup: (userId?: string, employeeId?: string) =>
    E(`/configuration/lookup`, {
      employee_id: employeeId,
      user_id: userId,
    })
      ._get<PaydaysEmployeeConfiguration>()
      .seal(),
  flagLookup: (featureFlag: string) =>
    E(`/configuration/flag`, {
      feature_flag: featureFlag,
    })
      ._get<PaydaysFeatureFlagConfiguration[]>()
      .seal(),
}

export const communicate = {
  emailsSent: (userId?: string) =>
    E(`/comm/sent`, {
      user_id: userId,
    })
      ._get<PaydaysUserEmail[]>()
      .seal(),
  eventLogs: (
    emailLogId: string,
    sendgridMessageId: string
  ) =>
    E(`/comm/eventlogs`, {
      email_log_id: emailLogId,
      sendgrid_message_id: sendgridMessageId,
    })
      ._get<PaydaysCommsEventLog[]>()
      .seal(),
}

export const rctAnalysis = {
  dauStats: (id?: string) =>
    E(`/rcta/experiments/${id}/stats/daus`)
      ._get<PaydaysRctaTreatment[]>()
      .seal(),
  experiments: () =>
    E("/rcta/experiments")
      ._get<PaydaysRctaExperiment[]>()
      .seal(),
  streamStats: (id?: string) =>
    E(`/rcta/experiments/${id}/stats/streams`)
      ._get<PaydaysRctaTreatment[]>()
      .seal(),
  treatments: (id?: string) =>
    E(`/rcta/experiments/${id}/treatments`)
      ._get<PaydaysRctaTreatment[]>()
      .seal(),
}

export const dpe = {
  advanceLogs: (id?: string | null) =>
    E(`/dpe/advance_logs/${id}`)
      ._get<PaydaysEmployeeDPEAdvanceLog[]>()
      .seal(),
}

export const savingsaccounts = {
  employeeAccount: (id?: string | null) =>
    E(`/savingsaccounts/employee/${id}`)
      ._get<PaydaysSavingsAccount>()
      .seal(),
  employeeApplications: (id?: string | null) =>
    E(`/savingsaccounts/employee/${id}/applications`)
      ._get<PaydaysSavingsAccountEmployeeApplications>()
      .seal(),
  reprocessEmployeeApplications: (id?: string | null) =>
    E(
      `/savingsaccounts/employee/${id}/applications/reprocess`
    )
      ._post<PaydaysMutationResponse>()
      .seal(),
}

export const kyc = {
  checks: (user_id?: string) =>
    E(`/kyc/checks`, { user_id })._get<IdCheck[]>().seal(),
  manualAddressVerification: (id?: string | null) =>
    E(`/kyc/employee/${id}/manual_address_verification`)
      ._post<PaydaysMutationResponse>()
      .seal(),
}

export const person = {
  byUserId: (user_id?: string) =>
    E(`/person`, { user_id })._get<Person>().seal(),
}

export const dueDiligence = {
  checks: (user_id?: string, type?: string) =>
    E(`/due_diligence/checks`, { type, user_id })
      ._get<DueDiligenceCheck[]>()
      .seal(),
  monitors: (user_id?: string) =>
    E(`/due_diligence/monitors`, { user_id })
      ._get<DueDiligenceMonitor[]>()
      .seal(),
  status: (user_id?: string) =>
    E(`/due_diligence/status`, {
      user_id,
    })._get<DueDiligenceStatus>(),
}

export const rootActions = {
  all: (sdate?: string | null, edate?: string | null) =>
    E(`/root-actions/`, { edate, sdate })
      ._get<RootAction[]>()
      .seal(),
  review: (id?: string) =>
    E(`/root-actions/${id}/review`)
      ._post<RootActionReview>()
      .seal(),
}

export const vouchers = {
  retailers: (employeeId?: string) =>
    E(`/vouchers/retailers/${employeeId}`)
      ._get<void, { retailers: PaydaysVoucherRetailer[] }>()
      .seal(),
}

export const coach = {
  financialHealthScore: (employeeId?: string) =>
    E(`/coach/financial-score/${employeeId}`)
      ._get<PaydaysFinancialHealthScore>()
      .seal(),
}

export const openbanking = {
  transactionsByEmployeeId: (
    employeeId?: string,
    startDate?: string,
    endDate?: string
  ) =>
    E(`/open_banking/transactions/${employeeId}`, {
      end_date: endDate,
      start_date: startDate,
    })
      ._get<PaydaysOpenBankingTransaction[]>()
      .seal(),
}

export const debitCardUS = {
  applicationHistory: (application_id?: string | null) =>
    E(
      `/debitcard_us/applications/${application_id}/status_history`
    )
      ._get<DebitcardApplicationHistoryUS[]>()
      .seal(),
  applications: (id?: string | null) =>
    E(`/debitcard_us/user/${id}/applications`)
      ._get<DebitcardApplicationUS[]>()
      .seal(),
  list: (id?: string | null) =>
    E(`/debitcard_us/user/${id}`)
      ._get<DebitcardUS[]>()
      .seal(),
  reprocessApplication: (user_id: string) =>
    E(
      `/debitcard_us/user/${user_id}/applications/reprocess`
    )
      ._post<void>()
      .seal(),
  update_status: (card_id: string, user_id: string) =>
    E(`/debitcard_us/${card_id}/user/${user_id}/status`)
      ._put<DebitcardUS[]>()
      .seal(),
}

export const paymentcards = {
  fulfillmentHistory: (card_id?: string | null) =>
    E(`/paymentcards/${card_id}/fulfillment_history`)
      ._get<DebitcardStatusHistoryUS[]>()
      .seal(),
  statusHistory: (card_id?: string | null) =>
    E(`/paymentcards/${card_id}/status_history`)
      ._get<DebitcardStatusHistoryUS[]>()
      .seal(),
}

export const paymentAccountUS = {
  get: (user_id?: string | null) =>
    E(`/paymentaccounts/user/${user_id}`)
      ._get<PaymentAccountUS>()
      .seal(),
  issue_card: (account_id: string) =>
    E(`/paymentaccounts/${account_id}/cards`)
      ._post()
      .seal(),
}

export const documents = {
  byCode: (code?: string) =>
    E(`/documents/type/${code}`)
      ._get<
        PaydaysCompanyDocumentType,
        PaydaysCompanyDocumentType
      >()
      .seal(),
  byId: (document_id?: string) =>
    E(`documents/${document_id}`)
      ._post<PaydaysCreateDocument>()
      .seal(),
}

export const companyDocuments = {
  byCompany: (company_id?: string) =>
    E(`/documents/companies/${company_id}`)
      ._get<
        PaydaysCompanyDocument[],
        PaydaysCompanyDocument[]
      >()
      .seal(),
  byCompanyDocumentCode: (
    company_id?: string,
    code?: string,
    version?: string
  ) =>
    E(
      `/documents/companies/${company_id}/${code}${
        version ? "/" + version : ""
      }`
    )
      ._get<
        PaydaysGetCompanyDocumentDownloadUrl,
        PaydaysGetCompanyDocumentDownloadUrl
      >()
      ._patch<PaydaysPatchCompanyDocument>()
      .seal(),
  documentTypes: () =>
    E("/documents/companies/")._get<
      PaydaysCompanyDocumentTypes[],
      PaydaysCompanyDocumentTypes[]
    >(),
}

export const companyIntegrationTransforms = {
  all: () =>
    E(`/company_integration_transforms/`)
      ._get<PaydaysCompanyIntegrationTransforms[]>()
      ._post()
      .seal(),
  byTransformId: (
    company_integration_transform_id: string
  ) =>
    E(
      `/company_integration_transforms/${company_integration_transform_id}/integrations`
    )
      ._get<PaydaysLinkedIntegrations[]>()
      .seal(),
  entitySchema: () =>
    E(`/company_integration_transforms/entity_schema`)
      ._get<PaydaysEntitySchema>()
      .seal(),
  test: () =>
    E(`/company_integration_transforms/transform_test`)
      ._post<PaydaysCompanyIntegrationTransformsTest>()
      .seal(),
  update: (company_integration_transform_id: string) =>
    E(
      `/company_integration_transforms/${company_integration_transform_id}`
    )
      ._patch()
      .seal(),
}
