import assert from "assert"
import React from "react"
import type { Params } from "react-router-dom"
import { generatePath, Navigate, Outlet, Route } from "react-router-dom"
import { PrivateRoute } from "components"
import { Shell } from "shell/Shell"
import Login from "pages/Login/Login"
import { ScrollToTopRoute } from "components"
import PaySchedulesDates from "pages/PaySchedulesDates/PaySchedulesDates"
import NotFound from "pages/NotFound/NotFound"
import RootActions from "pages/RootActions/RootActions"
import ActionRequests from "pages/RootActions/ActionRequests"
import UserPaymentCards from "pages/User/tabs/UserPaymentCards/UserPaymentCards"
import Device from "pages/Device/Device"
import DeviceDetails from "pages/Device/tabs/DeviceDetails/DeviceDetails"
import DeviceExperiments from "pages/Device/tabs/DeviceExperiments/DeviceExperiments"
import Category from "pages/DiscountConfig/Category/Category"

const AddCompany = React.lazy(() => import("pages/AddCompany/AddCompany"))
const RegisterCompany = React.lazy(() => import("pages/RegisterCompany/RegisterCompany"))
const JsonTemplates = React.lazy(() => import("pages/JsonTemplates/JsonTemplates"))
const JobBoard = React.lazy(() => import("pages/JobBoard/JobBoard"))
const JobRoles = React.lazy(() => import("pages/JobRoles/JobRoles"))
const Admins = React.lazy(() => import("pages/Admins/Admins"))
const Overpayments = React.lazy(() => import("pages/Overpayments/Overpayments"))
const BankingDetails = React.lazy(() => import("pages/BankingDetails/BankingDetails"))
const Campaigns = React.lazy(() => import("pages/Campaigns/Campaigns"))
const AllCampaigns = React.lazy(() => import("pages/Campaigns/tabs/AllCampaigns/AllCampaigns"))
const AddCampaign = React.lazy(() => import("pages/Campaigns/tabs/AddCampaign/AddCampaign"))
const CampaignEdit = React.lazy(() => import("pages/Campaign/Campaign"))
const Companies = React.lazy(() => import(`pages/Companies/Companies.${process.env.REACT_APP_BUILD_REGION}`))
const Company = React.lazy(() => import("pages/Company/Company"))
const Contacts = React.lazy(() => import("pages/Contacts/Contacts"))
const CorporateGroups = React.lazy(() => import("pages/CorporateGroups/CorporateGroups"))
const Crontab = React.lazy(() => import("pages/Crontab/Crontab"))
const ACHDebit = React.lazy(() => import("pages/ACHDebit/ACHDebit"))
const ACHDebitExclusionList = React.lazy(() => import("pages/ACHDebit/tabs/ExclusionList/ExclusionList"))
const ACHDebitAddExclusionPhrase = React.lazy(() => import("pages/ACHDebit/tabs/AddExclusionPhrase/AddExclusionPhrase"))
const ACHDebitInclusionList = React.lazy(() => import("pages/ACHDebit/tabs/InclusionList/InclusionList"))
const ACHDebitAddInclusionPhrase = React.lazy(() => import("pages/ACHDebit/tabs/AddInclusionPhrase/AddInclusionPhrase"))
const Documentation = React.lazy(() => import("pages/Documentation/Documentation"))
const EnrollmentIssues = React.lazy(() => import("pages/EnrollmentIssues/EnrollmentIssues"))
const EmailTemplates = React.lazy(() => import("pages/EmailTemplates/EmailTemplates"))
const EmailTemplateEdit = React.lazy(() => import("pages/EmailTemplate/EmailTemplate"))
const AllEmailTemplates = React.lazy(() => import("pages/EmailTemplates/tabs/AllTemplates/AllTemplates"))
const DiscountsRoot = React.lazy(() => import("pages/Discounts/Discounts"))
const AllRetailers = React.lazy(() => import("pages/Discounts/tabs/AllRetailers/AllRetailers"))
const HomepageProductFeed = React.lazy(() => import("pages/Discounts/tabs/HomepageProductFeed/HomepageProductFeed"))
const PromotedDiscounts = React.lazy(() => import("pages/Discounts/tabs/PromotedDiscounts/PromotedDiscounts"))
const DiscountCategories = React.lazy(() => import("pages/Discounts/tabs/Categories/Categories"))
const RetailerEdit = React.lazy(() => import("pages/DiscountConfig/Retailer"))
const DiscountEdit = React.lazy(() => import("pages/DiscountConfig/Discount/Discount"))
const AddEmailTemplate = React.lazy(() => import("pages/EmailTemplates/tabs/AddTemplate/AddTemplate"))
const Employee = React.lazy(() => import("pages/Employee/Employee"))
const Jobs = React.lazy(() => import("pages/Jobs/Jobs"))
const Expenses = React.lazy(() => import("pages/Expenses/Expenses"))
const Transfers = React.lazy(() => import("pages/Transfers/Transfers"))
const EmployeeStates = React.lazy(() => import("pages/EmployeeStates/EmployeeStates"))
const GlobalAlert = React.lazy(() => import("pages/GlobalAlert/GlobalAlert"))
const HelpCenter = React.lazy(() => import("pages/HelpCenter/HelpCenter"))
const AllowList = React.lazy(() => import("pages/AllowList/AllowList"))
const CompanyIntegrationTransforms = React.lazy(
  () => import("pages/CompanyIntegrationTransforms/CompanyIntegrationTransforms")
)
const KillBotJobsForm = React.lazy(() => import("pages/BotJobs/BotJobs"))
const NewRoot = React.lazy(() => import("pages/NewRoot/NewRoot"))
const PaySchedules = React.lazy(() => import("pages/PaySchedules/PaySchedules"))
const Registrations = React.lazy(() => import("pages/Registrations/Registrations"))
const Roots = React.lazy(() => import("pages/Roots/Roots"))
const RctaExperiments = React.lazy(() => import("pages/RctaExperiments/RctaExperiments"))
const RrctaExperiment = React.lazy(() => import("pages/RctaExperiment/RctaExperiment"))
const FeatureFlags = React.lazy(() => import("pages/FeatureFlags/FeatureFlags"))
const AllPaySchedules = React.lazy(() => import("pages/PaySchedules/tabs/AllPaySchedules/AllPaySchedules"))
const DefaultPaySchedules = React.lazy(() => import("pages/PaySchedules/tabs/DefaultPaySchedules/DefaultPaySchedules"))
const AddPaySchedule = React.lazy(() => import("pages/PaySchedules/tabs/AddPaySchedule/AddPaySchedule"))
const SyncResults = React.lazy(() => import("pages/SyncResults/SyncResults"))
const ExpectedSalaries = React.lazy(() => import("pages/Payday/tabs/ExpectedSalaries/ExpectedSalaries"))
const ActualSalaries = React.lazy(() => import("pages/Payday/tabs/ActualSalaries/ActualSalaries"))
const NoPay = React.lazy(() => import("pages/Payday/tabs/NoPay/NoPay"))
const FailedSalaries = React.lazy(() => import("pages/Payday/tabs/FailedSalaries/FailedSalaries"))
const Payday = React.lazy(() => import("pages/Payday/Payday"))
const Insurance = React.lazy(() => import("pages/Insurance/Insurance"))
const CreditTransactions = React.lazy(() => import("pages/User/tabs/UserCredit/Transactions"))
const SicknessReport = React.lazy(() => import("pages/Insurance/tabs/SicknessReport/SicknessReport"))
const SicknessPolicies = React.lazy(() => import("pages/Insurance/tabs/SicknessPolicies/SicknessPolicies"))
const PaymentSources = React.lazy(() => import("pages/PaymentSources/PaymentSources"))
const Transfer = React.lazy(() => import("pages/Transfer/Transfer"))
const Optouts = React.lazy(() => import("pages/Optouts/Optouts"))
const AllOptouts = React.lazy(() => import("pages/Optouts/tabs/AllOptouts/AllOptouts"))
const OptoutsReady = React.lazy(() => import("pages/Optouts/tabs/OptoutsReady/OptoutsReady"))
const OptoutsPending = React.lazy(() => import("pages/Optouts/tabs/OptoutsPending/OptoutsPending"))
const OptoutsReversed = React.lazy(() => import("pages/Optouts/tabs/OptoutsReversed/OptoutsReversed"))
const Salary = React.lazy(() => import("pages/Salary/Salary"))
const Expense = React.lazy(() => import("pages/Expense/Expense"))
const User = React.lazy(() => import("pages/User/User"))
const UserDetails = React.lazy(() => import("pages/User/tabs/UserDetails/UserDetails"))
const UserEmployments = React.lazy(() => import("pages/User/tabs/UserEmployments/UserEmployments"))
const UserHistory = React.lazy(() => import("pages/User/tabs/UserHistory/UserHistory"))
const UserLogins = React.lazy(() => import("pages/User/tabs/UserLogins/UserLogins"))
const UserDevices = React.lazy(() => import("pages/User/tabs/UserDevices/UserDevices"))
const UserElevations = React.lazy(() => import("pages/User/tabs/UserElevations/UserElevations"))
const UserEmails = React.lazy(() => import("pages/User/tabs/UserEmails/UserEmails"))
const UserExperiments = React.lazy(() => import("pages/User/tabs/UserExperiments/UserExperiments"))
const UserCredit = React.lazy(() => import("pages/User/tabs/UserCredit/UserCredit"))
const UserSDL = React.lazy(() => import("pages/User/tabs/UserSDL/UserSDL"))
const UserDueDiligence = React.lazy(() => import("pages/User/tabs/UserDueDiligence/UserDueDilligence"))
const UserProductApplications = React.lazy(
  () => import("pages/User/tabs/UserProductApplications/UserProductApplications")
)
const UserDirectDebitCollections = React.lazy(() => import("pages/User/tabs/UserDirectDebitCollections"))
const UserCreditTesting = React.lazy(() => import("pages/User/tabs/UserCreditTesting"))
const UserAgreements = React.lazy(() => import("pages/User/tabs/UserAgreements"))
const UserInsurance = React.lazy(() => import("pages/User/tabs/UserInsurance/UserInsurance"))
const Charges = React.lazy(() => import("pages/Charges/Charges"))
const Dialogs = React.lazy(() => import("pages/Dialogs/Dialogs"))
const DialogEdit = React.lazy(() => import("pages/Dialog/Dialog"))
const AllDialogs = React.lazy(() => import("pages/Dialogs/tabs/AllDialogs/AllDialogs"))
const AddDialog = React.lazy(() => import("pages/Dialogs/tabs/AddDialog/AddDialog"))
const AllArticles = React.lazy(() => import("pages/HelpCenter/tabs/AllArticles/AllArticles"))
const AddEditArticle = React.lazy(() => import("pages/HelpCenter/tabs/AddEditArticle/AddEditArticle"))
const LookupLedger = React.lazy(() => import("pages/LookupLedger/LookupLedger"))
const RemoveBadAccruals = React.lazy(() => import("pages/RemoveBadAccruals/RemoveBadAccruals"))
const ActionApprovalQueue = React.lazy(() => import("pages/RootActions/ActionApprovalQueue"))
const Raise = React.lazy(() => import("pages/Raise/Raise"))
const DirectDebitCollections = React.lazy(() => import("pages/DirectDebitCollections"))
const DirectDebitMandate = React.lazy(() => import("pages/DirectDebitMandate"))

type RouteConfig =
  | PageConfig &
      (
        | {
            routes?: RouteConfig[]
            getUrl?: never
          }
        | {
            routes?: never
            getUrl?: (params?: Record<string, string | undefined>) => string
          }
      )

type PageConfig = {
  pathPrefix?: string
  path: string
  element: JSX.Element
  index?: boolean
  options?: {
    private?: boolean
    scrollToTop?: boolean
  }
}

function withUrl<T extends string | never>({ pathPrefix, path, ...rest }: PageConfig) {
  return {
    ...rest,
    pathPrefix,
    path,
    getUrl: (params?: Params<T>) => generatePath("/" + (pathPrefix ? pathPrefix + "/" : "") + path, params),
  }
}

const pages = (function () {
  const basePages = {
    login: withUrl({
      path: "login",
      element: <Login />,
    }),
    newRoot: withUrl({
      path: "newroot",
      element: <NewRoot />,
    }),
    authCallback: withUrl({
      path: "auth_callback",
      element: <Login />,
    }),
    addCompany: withUrl<never>({
      path: "newco",
      options: { private: true },
      element: <AddCompany />,
    }),
    registerCompany: withUrl<never>({
      path: "register-company",
      options: { private: true },
      element: <RegisterCompany />,
    }),
    jsonTemplates: withUrl<never>({
      path: "data/json-templates",
      options: { private: true },
      element: <JsonTemplates />,
    }),
    jobBoard: withUrl<never>({
      path: "data/job-board",
      options: { private: true },
      element: <JobBoard />,
    }),
    jobRoles: withUrl<never>({
      path: "data/job-roles",
      options: { private: true },
      element: <JobRoles />,
    }),
    companies: withUrl<never>({
      path: "data/companies",
      options: { private: true },
      element: <Companies />,
    }),
    documentation: withUrl<never>({
      path: "data/documentation",
      options: { private: true },
      element: <Documentation />,
    }),
    company: withUrl<"id">({
      path: "data/companies/:id/*",
      options: { private: true, scrollToTop: true },
      element: <Company />,
    }),
    allPaySchedules: withUrl({
      pathPrefix: "data/payschedules",
      path: "all",
      index: true,
      options: { private: true },
      element: <AllPaySchedules />,
    }),
    defaultPaySchedules: withUrl({
      pathPrefix: "data/payschedules",
      path: "default",
      options: { private: true },
      element: <DefaultPaySchedules />,
    }),
    addPaySchedules: withUrl({
      pathPrefix: "data/payschedules",
      path: "add",
      options: { private: true },
      element: <AddPaySchedule />,
    }),
    payScheduleDates: withUrl<"id">({
      path: "data/payschedules/:id",
      options: { private: true },
      element: <PaySchedulesDates />,
    }),
    botJobs: withUrl<never>({
      path: "data/kill_hung_bot_jobs",
      options: { private: true },
      element: <KillBotJobsForm />,
    }),
    employee: withUrl<"id">({
      path: "data/employees/:id/*",
      options: { private: true, scrollToTop: true },
      element: <Employee />,
    }),
    syncResults: withUrl<"syncResultId" | "companyId" | "integrationId">({
      path: "data/sync_results/:companyId/:integrationId/:syncResultId",
      options: { private: true, scrollToTop: true },
      element: <SyncResults />,
    }),
    bankingDetails: withUrl<"providerId" | "entityId" | "id">({
      path: "banking/:providerId/:entityId/:id/details",
      options: { private: true, scrollToTop: true },
      element: <BankingDetails />,
    }),
    crontab: withUrl({
      path: "crontab",
      options: { private: true, scrollToTop: true },
      element: <Crontab />,
    }),
    achDebitExclusionList: withUrl({
      pathPrefix: "ach_debit",
      path: "exclusion_list",
      index: true,
      options: { private: true },
      element: <ACHDebitExclusionList />,
    }),
    achDebitAddExclusionPhrase: withUrl({
      pathPrefix: "ach_debit",
      path: "add_exclusion_phrase",
      options: { private: true },
      element: <ACHDebitAddExclusionPhrase />,
    }),
    achDebitInclusionList: withUrl({
      pathPrefix: "ach_debit",
      path: "inclusion_list",
      index: true,
      options: { private: true },
      element: <ACHDebitInclusionList />,
    }),
    achDebitAddInclusionPhrase: withUrl({
      pathPrefix: "ach_debit",
      path: "add_inclusion_phrase",
      options: { private: true },
      element: <ACHDebitAddInclusionPhrase />,
    }),
    jobs: withUrl({
      path: "data/jobs",
      options: { private: true, scrollToTop: true },
      element: <Jobs />,
    }),
    registrations: withUrl({
      path: "data/registrations",
      options: { private: true, scrollToTop: true },
      element: <Registrations />,
    }),
    roots: withUrl({
      path: "data/rootusers",
      options: { private: true, scrollToTop: true },
      element: <Roots />,
    }),
    rctaExperiments: withUrl({
      path: "data/rcta",
      options: { private: true, scrollToTop: true },
      element: <RctaExperiments />,
    }),
    rctaExperiment: withUrl<"id">({
      path: "data/rcta/:id",
      options: { private: true, scrollToTop: true },
      element: <RrctaExperiment />,
    }),
    featureFlags: withUrl({
      path: "data/feature_flags",
      options: { private: true, scrollToTop: true },
      element: <FeatureFlags />,
    }),
    employeeStates: withUrl({
      path: "data/change_employee_states",
      options: { private: true, scrollToTop: true },
      element: <EmployeeStates />,
    }),
    globalAlert: withUrl({
      path: "data/global-alert",
      options: { private: true, scrollToTop: true },
      element: <GlobalAlert />,
    }),
    allowList: withUrl({
      path: "data/allow_list",
      options: { private: true, scrollToTop: true },
      element: <AllowList />,
    }),
    companyIntegrationTransforms: withUrl({
      path: "data/companyintegrationtransforms",
      options: { private: true, scrollToTop: true },
      element: <CompanyIntegrationTransforms />,
    }),
    corporateGroups: withUrl({
      path: "data/corporate_groups",
      options: { private: true, scrollToTop: true },
      element: <CorporateGroups />,
    }),
    expenses: withUrl({
      path: "data/expenses",
      options: { private: true, scrollToTop: true },
      element: <Expenses />,
    }),
    allEmailTemplates: withUrl({
      pathPrefix: "data/campaign_emails",
      path: "all",
      options: { private: true, scrollToTop: true },
      element: <AllEmailTemplates />,
    }),
    addEmailTemplate: withUrl({
      pathPrefix: "data/campaign_emails",
      path: "add",
      options: { private: true, scrollToTop: true },
      element: <AddEmailTemplate />,
    }),
    editEmailTemplate: withUrl<"id">({
      path: "data/campaign_emails/:id/edit",
      options: { private: true, scrollToTop: true },
      element: <EmailTemplateEdit />,
    }),
    allDialogs: withUrl({
      pathPrefix: "data/dialogs",
      path: "all",
      options: { private: true, scrollToTop: true },
      element: <AllDialogs />,
    }),
    addDialog: withUrl({
      pathPrefix: "data/dialogs",
      path: "add",
      options: { private: true, scrollToTop: true },
      element: <AddDialog />,
    }),
    editDialog: withUrl<"id">({
      path: "data/dialogs/:id/edit",
      options: { private: true, scrollToTop: true },
      element: <DialogEdit />,
    }),
    allArticles: withUrl({
      pathPrefix: "data/help-center",
      path: "all",
      options: { private: true, scrollToTop: true },
      element: <AllArticles />,
    }),
    addArticle: withUrl({
      pathPrefix: "data/help-center",
      path: "add",
      options: { private: true, scrollToTop: true },
      element: <AddEditArticle />,
    }),
    editArticle: withUrl({
      pathPrefix: "data/help-center",
      path: "edit/:id",
      options: { private: true, scrollToTop: true },
      element: <AddEditArticle />,
    }),
    transfers: withUrl({
      path: "data/transfers",
      options: { private: true, scrollToTop: true },
      element: <Transfers />,
    }),
    paymentSources: withUrl({
      path: "data/payment_sources",
      options: { private: true, scrollToTop: true },
      element: <PaymentSources />,
    }),
    admins: withUrl({
      path: "data/admins",
      options: { private: true, scrollToTop: true },
      element: <Admins />,
    }),
    insurance: withUrl({
      path: "insurance",
      options: { private: true, scrollToTop: true },
      element: <Insurance />,
    }),
    sicknessWakamReport: withUrl({
      pathPrefix: "insurance",
      path: "report",
      options: { private: true },
      element: <SicknessReport />,
    }),
    sicknessInsurancePolicies: withUrl({
      pathPrefix: "insurance",
      path: "policies",
      options: { private: true },
      element: <SicknessPolicies />,
    }),
    creditTransactions: withUrl({
      pathPrefix: "credit",
      path: "transactions",
      options: { private: true, scrollToTop: true },
      element: <CreditTransactions />,
    }),
    expectedSalaries: withUrl({
      pathPrefix: "data/salaries/payday",
      path: "expected",
      options: { private: true },
      element: <ExpectedSalaries />,
    }),
    actualSalaries: withUrl({
      pathPrefix: "data/salaries/payday",
      path: "actual",
      options: { private: true },
      element: <ActualSalaries />,
    }),
    noPay: withUrl({
      pathPrefix: "data/salaries/payday",
      path: "nopay",
      options: { private: true },
      element: <NoPay />,
    }),
    failedSalaries: withUrl({
      pathPrefix: "data/salaries/payday",
      path: "failed",
      options: { private: true },
      element: <FailedSalaries />,
    }),
    allCampaigns: withUrl({
      pathPrefix: "campaigns",
      path: "all",
      options: { private: true },
      element: <AllCampaigns />,
    }),
    addCampaign: withUrl({
      pathPrefix: "campaigns",
      path: "new",
      options: { private: true },
      element: <AddCampaign />,
    }),
    editCampaign: withUrl({
      path: "campaign/:id/edit",
      options: { private: true },
      element: <CampaignEdit />,
    }),
    transfer: withUrl<"id">({
      path: "data/transfers/:id",
      options: { private: true, scrollToTop: true },
      element: <Transfer />,
    }),
    allOptouts: withUrl({
      pathPrefix: "data/optouts",
      path: "all",
      options: { private: true, scrollToTop: true },
      element: <AllOptouts />,
    }),
    optoutsReady: withUrl({
      pathPrefix: "data/optouts",
      path: "ready",
      options: { private: true, scrollToTop: true },
      element: <OptoutsReady />,
    }),
    optoutsPending: withUrl({
      pathPrefix: "data/optouts",
      path: "pending",
      options: { private: true, scrollToTop: true },
      element: <OptoutsPending />,
    }),
    optoutsReversed: withUrl({
      pathPrefix: "data/optouts",
      path: "reversed",
      options: { private: true, scrollToTop: true },
      element: <OptoutsReversed />,
    }),
    salary: withUrl<"id">({
      path: "data/salaries/:id",
      options: { private: true, scrollToTop: true },
      element: <Salary />,
    }),
    enrollmentIssues: withUrl({
      path: "data/enrollment_issues",
      options: { private: true, scrollToTop: true },
      element: <EnrollmentIssues />,
    }),
    expense: withUrl<"id">({
      path: "data/expenses/:id",
      options: { private: true, scrollToTop: true },
      element: <Expense />,
    }),
    userDetails: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "details",
      index: true,
      options: { private: true, scrollToTop: true },
      element: <UserDetails />,
    }),
    userEmployments: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "employments",
      options: { private: true },
      element: <UserEmployments />,
    }),
    userHistory: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "history",
      options: { private: true },
      element: <UserHistory />,
    }),
    userLogins: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "logins",
      options: { private: true },
      element: <UserLogins />,
    }),
    userDevices: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "devices",
      options: { private: true },
      element: <UserDevices />,
    }),
    userElevations: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "elevations",
      options: { private: true },
      element: <UserElevations />,
    }),
    userEmails: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "emails",
      options: { private: true },
      element: <UserEmails />,
    }),
    userExperiments: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "experiments",
      options: { private: true },
      element: <UserExperiments />,
    }),
    userCredit: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "credit",
      options: { private: true },
      element: <UserCredit />,
    }),
    userSDL: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "sdl",
      options: { private: true },
      element: <UserSDL />,
    }),
    userDueDiligence: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "due-diligence",
      options: { private: true },
      element: <UserDueDiligence />,
    }),
    userCreditApplications: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "credit_applications",
      options: { private: true },
      element: <UserProductApplications productType="credit" />,
    }),
    userSDLApplications: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "sdl_applications",
      options: { private: true },
      element: <UserProductApplications productType="sdl" />,
    }),
    userCreditTesting: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "credit-testing",
      options: { private: true },
      element: <UserCreditTesting />,
    }),
    userAgreements: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "agreements",
      options: { private: true },
      element: <UserAgreements />,
    }),
    userDirectDebitCollections: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "dd-collections",
      options: { private: true },
      element: <UserDirectDebitCollections />,
    }),
    directDebitCollections: withUrl({
      pathPrefix: "dd-collections",
      path: "",
      options: { private: true, scrollToTop: true },
      element: <DirectDebitCollections />,
    }),
    directDebitMandate: withUrl<"id">({
      pathPrefix: "data/dd-mandates/:id",
      path: "",
      options: { private: true },
      element: <DirectDebitMandate />,
    }),
    userInsurance: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "insurance",
      options: { private: true },
      element: <UserInsurance />,
    }),
    userPaymentCards: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "debitcad_us",
      options: { private: true },
      element: <UserPaymentCards />,
    }),
    charges: withUrl<"id">({
      path: "data/charges/:id",
      options: { private: true, scrollToTop: true },
      element: <Charges />,
    }),
    contacts: withUrl({
      path: "data/contacts",
      options: { private: true, scrollToTop: true },
      element: <Contacts />,
    }),
    lookupLedgers: withUrl({
      path: "/banking/lookup-ledger",
      options: { private: true, scrollToTop: true },
      element: <LookupLedger />,
    }),
    removeBadAccruals: withUrl({
      path: "data/remove-bad-accruals",
      options: { private: true, scrollToTop: true },
      element: <RemoveBadAccruals />,
    }),
    rootActionsReviewQueue: withUrl({
      pathPrefix: "root-actions",
      path: "queue",
      options: { scrollToTop: true },
      element: <ActionApprovalQueue />,
    }),
    rootActionsRequest: withUrl({
      pathPrefix: "root-actions",
      path: "request",
      options: { scrollToTop: true },
      element: <ActionRequests />,
    }),
    rootActions: withUrl({
      path: "root-actions",
      options: { private: true, scrollToTop: true },
      element: <RootActions />,
    }),
    overpayments: withUrl<never>({
      path: "data/overpayments",
      element: <Overpayments />,
    }),
    deviceDetails: withUrl<"id">({
      pathPrefix: "data/devices/:id",
      path: "details",
      index: true,
      options: { private: true, scrollToTop: true },
      element: <DeviceDetails />,
    }),
    deviceExperiments: withUrl<"id">({
      pathPrefix: "data/devices/:id",
      path: "experiments",
      options: { private: true },
      element: <DeviceExperiments />,
    }),
    discounts: withUrl({
      pathPrefix: "data/discounts",
      path: "all",
      element: <DiscountsRoot />,
    }),
    discountsRetailers: withUrl({
      pathPrefix: "data/discounts",
      path: "retailers",
      element: <AllRetailers />,
    }),
    discountsPromoted: withUrl({
      pathPrefix: "data/discounts",
      path: "promoted",
      element: <PromotedDiscounts />,
    }),
    discountsProductFeed: withUrl({
      pathPrefix: "data/discounts",
      path: "product_feed",
      element: <HomepageProductFeed />,
    }),
    discountsCategories: withUrl({
      pathPrefix: "data/discounts",
      path: "categories",
      element: <DiscountCategories />,
    }),
    discountsCategory: withUrl<"id">({
      path: "data/discounts/category/:id",
      element: <Category />,
    }),
    discountsRetailer: withUrl<"id">({
      path: "data/discounts/retailer/:id",
      element: <RetailerEdit />,
    }),
    discountEditor: withUrl<"id">({
      path: "data/discounts/discount/:id",
      element: <DiscountEdit />,
    }),
    raise: withUrl<never>({
      path: "data/raise",
      options: { private: true },
      element: <Raise />,
    }),
  }

  const redirects = {
    root: withUrl({
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.companies.getUrl()} replace />,
    }),
    optoutsRoot: withUrl({
      pathPrefix: "data/optouts",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.optoutsPending.getUrl()} replace />,
    }),
    paySchedulesRoot: withUrl({
      pathPrefix: "data/payschedules",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.allPaySchedules.getUrl()} replace />,
    }),
    achDebitRoot: withUrl({
      pathPrefix: "ach_debit",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.achDebitExclusionList.getUrl()} replace />,
    }),
    paydaysRoot: withUrl({
      pathPrefix: "data/salaries/payday",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.expectedSalaries.getUrl()} replace />,
    }),
    insuranceRoot: withUrl({
      pathPrefix: "insurance",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.sicknessInsurancePolicies.getUrl()} replace />,
    }),
    rootActionsRoot: withUrl({
      pathPrefix: "root-actions",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.rootActionsReviewQueue.getUrl()} replace />,
    }),
    emailTemplatesRoot: withUrl({
      pathPrefix: "data/campaign_emails",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.allEmailTemplates.getUrl()} replace />,
    }),
    dialogsRoot: withUrl({
      pathPrefix: "data/dialogs",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.allDialogs.getUrl()} replace />,
    }),
    helpCenterRoot: withUrl({
      pathPrefix: "data/help-center",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.allArticles.getUrl()} replace />,
    }),
    campaignsRoot: withUrl({
      pathPrefix: "campaigns",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.allCampaigns.getUrl()} replace />,
    }),
    userRoot: withUrl<"id">({
      pathPrefix: "data/users/:id",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.userDetails.path} replace />,
    }),
    deviceRoot: withUrl<"id">({
      pathPrefix: "data/devices/:id",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.deviceDetails.path} replace />,
    }),
    discountsRoot: withUrl({
      pathPrefix: "data/discounts",
      path: "",
      options: { private: true },
      element: <Navigate to={basePages.discountsRetailers.getUrl()} replace />,
    }),
  }

  return {
    ...basePages,
    ...redirects,
  }
})()

const routeConfig: RouteConfig = {
  path: "*",
  element: <Outlet />,
  routes: [
    pages.login,
    pages.newRoot,
    pages.authCallback,
    {
      path: "*",
      element: <Shell />,
      options: { private: true },
      routes: [
        pages.root,
        pages.companies,
        pages.documentation,
        pages.company,
        pages.botJobs,
        pages.employee,
        pages.syncResults,
        pages.addCompany,
        pages.registerCompany,
        pages.jsonTemplates,
        pages.jobBoard,
        pages.jobRoles,
        pages.bankingDetails,
        pages.crontab,
        pages.jobs,
        pages.registrations,
        pages.roots,
        pages.rctaExperiments,
        pages.rctaExperiment,
        pages.featureFlags,
        pages.employeeStates,
        pages.globalAlert,
        pages.allowList,
        pages.companyIntegrationTransforms,
        pages.corporateGroups,
        pages.payScheduleDates,
        pages.expenses,
        pages.insurance,
        pages.editEmailTemplate,
        pages.transfers,
        pages.transfer,
        pages.salary,
        pages.paymentSources,
        pages.admins,
        pages.enrollmentIssues,
        pages.editCampaign,
        pages.editDialog,
        pages.expense,
        pages.charges,
        pages.contacts,
        pages.lookupLedgers,
        pages.removeBadAccruals,
        pages.rootActions,
        pages.overpayments,
        pages.discountsRetailer,
        pages.discountsCategories,
        pages.discountsCategory,
        pages.discountEditor,
        pages.raise,
        {
          path: "data/campaign_emails",
          element: <EmailTemplates />,
          routes: [pages.emailTemplatesRoot, pages.allEmailTemplates, pages.addEmailTemplate],
        },
        {
          path: "data/dialogs",
          element: <Dialogs />,
          routes: [pages.dialogsRoot, pages.allDialogs, pages.addDialog],
        },
        {
          path: "data/discounts",
          element: <DiscountsRoot />,
          routes: [
            pages.discountsRoot,
            pages.discountsRetailers,
            pages.discountsPromoted,
            pages.discountsCategories,
            pages.discountsProductFeed,
          ],
        },
        {
          path: "data/help-center",
          element: <HelpCenter />,
          routes: [pages.helpCenterRoot, pages.allArticles, pages.addArticle, pages.editArticle],
        },
        {
          path: "campaigns",
          element: <Campaigns />,
          routes: [pages.campaignsRoot, pages.allCampaigns, pages.addCampaign],
        },
        {
          path: "data/optouts",
          element: <Optouts />,
          routes: [
            pages.optoutsRoot,
            pages.allOptouts,
            pages.optoutsPending,
            pages.optoutsReady,
            pages.optoutsReversed,
          ],
        },
        {
          path: "data/payschedules",
          element: <PaySchedules />,
          routes: [pages.paySchedulesRoot, pages.allPaySchedules, pages.defaultPaySchedules, pages.addPaySchedules],
        },
        {
          path: "insurance",
          element: <Insurance />,
          routes: [pages.insuranceRoot, pages.sicknessInsurancePolicies, pages.sicknessWakamReport],
        },
        {
          path: "root-actions",
          element: <RootActions />,
          routes: [pages.rootActionsRoot, pages.rootActionsReviewQueue, pages.rootActionsRequest],
        },
        {
          path: "dd-collections",
          element: <DirectDebitCollections />,
          routes: [pages.directDebitCollections],
        },
        {
          path: "data/dd-mandates/:id",
          element: <DirectDebitMandate />,
          routes: [pages.directDebitMandate],
        },
        {
          path: "ach_debit",
          element: <ACHDebit />,
          routes: [
            pages.achDebitRoot,
            pages.achDebitExclusionList,
            pages.achDebitAddExclusionPhrase,
            pages.achDebitInclusionList,
            pages.achDebitAddInclusionPhrase,
          ],
        },
        {
          path: "data/salaries/payday",
          element: <Payday />,
          routes: [pages.paydaysRoot, pages.expectedSalaries, pages.actualSalaries, pages.noPay, pages.failedSalaries],
        },
        {
          path: "data/users/:id",
          element: <User />,
          routes: [
            pages.userRoot,
            pages.userDetails,
            pages.userEmployments,
            pages.userHistory,
            pages.userLogins,
            pages.userDevices,
            pages.userElevations,
            pages.userEmails,
            pages.userExperiments,
            pages.userCredit,
            pages.userSDL,
            pages.userDueDiligence,
            pages.userCreditApplications,
            pages.userSDLApplications,
            pages.userCreditTesting,
            pages.userInsurance,
            pages.userPaymentCards,
            pages.userAgreements,
            pages.userDirectDebitCollections,
          ],
        },
        {
          path: "data/devices/:id",
          element: <Device />,
          routes: [pages.deviceRoot, pages.deviceDetails, pages.deviceExperiments],
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ],
}

const configToElement = ({ options = {}, path, ...config }: RouteConfig) => {
  let element = config.element

  const hasValidSubroutes = (config.routes || []).every((item) => path === "*" || item.pathPrefix === path)

  assert(hasValidSubroutes, `Not all routes nested under the "${path}" path in routeConfig have a matching pathPrefix`)

  element = options.scrollToTop ? <ScrollToTopRoute>{element}</ScrollToTopRoute> : element
  element = options.private ? <PrivateRoute>{element}</PrivateRoute> : element

  // @sentry/react for RRV6 does not handle plain * routes, thus we skip the prop.
  return (
    <Route {...config} path={path === "*" ? undefined : path} key={path} element={element}>
      {config.routes?.map(configToElement) || null}
    </Route>
  )
}

export const rootRoute = configToElement(routeConfig)

export const routes: {
  [Key in keyof typeof pages]: typeof pages[Key]["getUrl"]
} = Object.fromEntries(Object.entries(pages).map(([page, { getUrl }]) => [page, getUrl])) as Record<
  keyof typeof pages,
  () => string
>
